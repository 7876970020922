import React, { useState, useEffect } from 'react'
import TextInput from '../../components/TextInput'
import { useTranslation } from "react-i18next"
import { Auth } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import { CText } from "../../components/Text"
import MobilePhoneInput from "../../components/Input/MobilePhone"
import ProfilePicture from "../../components/ProfilePicture/index"
import FormControl from '@mui/material/FormControl'
import { API, graphqlOperation } from 'aws-amplify'
import { CFormControlLabel, CFormLabel, CRadioGroup } from '../../components/Radio/Radio'
import { CDatePicker } from '../../components/DatePicker'
import CModal from "../../components/Modal"
import CButton from "../../components/Button"

import ProfilePhotoModal from '../../components/ProfilePicture/modal'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import moment from 'moment'

import { useDispatch } from 'react-redux'
import { useStyles } from './styles';
import { useSelector } from 'react-redux'

const COLOR_PRIMARY = '#45d69f';

const UserInfo = ({ updateInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector((state) => state?.user);

  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [gender, setGender] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [phone, setPhone] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getUserInfo();
      setIsLoaded(true)
    }
  
    fetchData();
  }, [userStored])

  const genders = [
    { "label": t('personGenders.0.longLabel'), "code": "M" },
    { "label": t('personGenders.1.longLabel'), "code": "F" }
  ];

  const getUserInfo = async () => {
    try {
      const userIdStored = userStored.info.id
      let user = await API.graphql({
        query: queries.getUser,
        variables: { id: userIdStored },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      user = user?.data?.getUser
  
      const userInfo = {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        birthday: user.birthday,
        gender: user.gender,
        email: user.email,
        phone_dial_code: user.phone_dial_code,
        phone_country_code: user.phone_country_code,
        phone_number: user.phone_number
      }
  
      setUser(userInfo);
      return userInfo;
    }
    catch (err) { console.log(err) }
  }

  const firstNameCb = (data) => setFirstName(data);
  const lastNameCb = (data) => setLastName(data);
  const genderCb = (data) => setGender(data);
  const birthdayCb = (data) => setBirthday(data);
  const phoneCb = (data) => setPhone(data);

  const onClickSave = async () => {
    const updatedUser = {
      id: user.id
    };

    if (firstName && firstName !== user.first_name) {
      updatedUser.first_name = firstName;
    }
    if (lastName && lastName !== user.last_name) {
      updatedUser.last_name = lastName;
    }
    if (gender && gender !== user.gender) {
      updatedUser.gender = gender;
    }
    if (birthday && birthday !== user.birthday) {
      updatedUser.birthday = birthday;
    }
    if (phone.phoneNumber !== user.phone_number ||
        phone.dialCode !== user.phone_dial_code ||
        phone.countryCode !== user.phone_country_code) {
      updatedUser.phone_number = phone.phoneNumber;
      updatedUser.phone_dial_code = phone.dialCode;
      updatedUser.phone_country_code = phone.countryCode;
    }

    if (Object.keys(updatedUser).length > 1) {
      let test = await API.graphql(graphqlOperation(mutations.updateUser, {
        input: updatedUser
      }));

      setFirstName(null);
      setLastName(null);
      setGender(null);
      setBirthday(null);
      setPhone({});
    }
  };

  return (
    <>
      <div className={classes.topButtonsContainer}>
        <CButton
          className={classes.topButton}
          label={t('edit_account.buttons.0.label')}
          type="contained"
          color={COLOR_PRIMARY}
          size={14}
          loader={true}
          onClick={async () => await onClickSave()}
        />
      </div>

      {isLoaded === true ?
        <Grid container spacing={1} alignItems="flex-start" className={classes.content}>
          <Grid container item xs={12} md={3}>
            <ProfilePicture
              className={classes.photo}
              owner={true}
              updatePhoto={true}
              onClick={() => setModalOpen({type: "userPhoto", value: true})}
            />
          </Grid>
          <Grid container item xs={12} md={9}>
            <div className={classes.formZone}>
              <TextInput
                className={classes.input}
                label={t('edit_account.inputs.0.label')}
                inputCallback={firstNameCb}
                defaultValue={user.first_name}
              />
              <TextInput
                className={classes.input}
                label={t('edit_account.inputs.1.label')}
                inputCallback={lastNameCb}
                defaultValue={user.last_name}
              />
              <CDatePicker
                className={classes.input}
                label={t('edit_account.inputs.2.label')}
                inputCallback={birthdayCb}
                defaultValue={user.birthday}
              />
              <div className={[classes.input, classes.radioInput].join(" ")}>
                <FormControl>
                  <CFormLabel id="radio-buttons-group" label={t('edit_account.inputs.3.label')}/>
                  <CRadioGroup
                    defaultValue={user.gender}
                    onChange={genderCb}
                  >
                    {genders.map((gender, index) => (
                      <CFormControlLabel
                        key={index}
                        label={gender.label}
                        value={gender.code}
                      />
                    ))}
                  </CRadioGroup>
                </FormControl>
              </div>
              <TextInput
                className={classes.input}
                label={t('edit_account.inputs.4.label')}
                disabled={true}
                defaultValue={user.email}
              />
              <MobilePhoneInput
                className={classes.input}
                initialCountryCode={user.phone_country_code}
                initialPhoneNumber={user.phone_number}
                onChange={phoneCb}
              />
            </div>
          </Grid>
        </Grid> : null
      }

      <CModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={<ProfilePhotoModal modalOpenCallback={(data) => setModalOpen(data)} />}
      >
      </CModal>
    </>
  );
};

export default UserInfo;
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    margin: 'auto',
    maxWidth: '800px',
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  content: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
  text: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));
export const createAssist = `mutation createAssist($input: CreateAssistInput!) {
  createAssist(input: $input) {
    id
  }
}`;

export const createBodyFat = `mutation createBodyFat($input: CreateBodyFatInput!) {
  createBodyFat(input: $input) {
    id
    date
    season
    user_id
    userBody_fatsId
    body_fat_percentage
  }
}`;

export const createClub = `mutation createClub($input: CreateClubInput!) {
  createClub(input: $input) {
    id
    names
    cities
    country_code
    logo
    federation_id
  }
}`;

export const createCard = `mutation createCard($input: CreateCardInput!) {
  createCard(input: $input) {
    id
    type
  }
}`;

export const createClubAdmin = `mutation createClubAdmin2($input: CreateClubAdmin2Input!) {
  createClubAdmin2(input: $input) {
    first_name
    email
    last_name
    club {
      id
      names
    }
  }
}`;

export const createCompetition = `mutation createCompetition($input: CreateCompetitionInput!) {
  createCompetition(input: $input) {
    id
    name
    country_code
    zone
    category
    gender
    logo
    federation_id
    type
  }
}`;

export const createCompetitionGroup = `mutation createCompetitionGroup($input: CreateCompetitionGroupInput!) {
  createCompetitionGroup(input: $input) {
    id
    name
    competition_id
    federation_number
  }
}`;

export const createCompetitionTeam = `mutation createCompetitionTeam($input: CreateCompetitionTeamInput!) {
  createCompetitionTeam(input: $input) {
    id
  }
}`;

export const createGoal = `mutation createGoal($input: CreateGoalInput!) {
  createGoal(input: $input) {
    id
  }
}`;

export const createMatch = `mutation createMatch($input: CreateMatchInput!) {
  createMatch(input: $input) {
    away_team_name
    date
    home_team_name
    hour
    id
    stadium_address
    stadium_name
    week
    home_team {
      id
      club {
        id
        logo
        names
        official
      }
    }
    away_team {
      id
      club {
        id
        logo
        names
        official
      }
    }
  }
}`;

export const createMatchPlayer = `mutation createMatchPlayer($input: CreateMatchPlayerInput!) {
  createMatchPlayer(input: $input) {
    id
    season
    captain
    match_id
    position
    user_id
  }
}`;

export const createPlayerInjury = `mutation createPlayerInjury($input: CreatePlayerInjuryInput!) {
  createPlayerInjury(input: $input) {
    duration
    injuryUsersId
    injury_id
    season
    start_date
    userInjuriesId
    user_id
  }
}`;

export const createSeason = `mutation createSeason($input: CreateSeasonInput!) {
  createSeason(input: $input) {
    id
    name
  }
}`;

export const createTeam = `mutation createTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    id
    name
    category
    gender
    club_id
  }
}`;

export const createTactic = `mutation createTactic($input: CreateTacticInput!) {
  createTactic(input: $input) {
    id
    title
  }
}`;

export const createTeamUser = `mutation createTeamUser($input: CreateTeamUserInput!) {
  createTeamUser(input: $input) {
    id
    user_id
    team_id
    position
    season
    user_type
    email
    invite_status
    invite_read
  }
}`;

export const createUserClubContribution = `mutation createUserClubContribution($input: CreateUserClubContributionInput!) {
  createUserClubContribution(input: $input) {
    id
    user_id
    club_id
    paid
    season
  }
}`;

export const createWeight = `mutation createWeight($input: CreateWeightInput!) {
  createWeight(input: $input) {
    id
    date
    season
    user_id
    userWeightsId
    weight
  }
}`;

export const deleteBodyFat = `mutation deleteBodyFat($input: DeleteBodyFatInput!) {
  deleteBodyFat(input: $input) {
    id
  }
}`;

export const deleteMatch = `mutation deleteMatch($input: DeleteMatchInput!) {
  deleteMatch(input: $input) {
    id
  }
}`;

export const deleteTeam = `mutation deleteTeam($input: DeleteTeamInput!) {
  deleteTeam(input: $input) {
    id
    name
    category
    gender
    club_id
  }
}`;

export const deleteTactic = `mutation deleteTactic($input: DeleteTacticInput!) {
  deleteTactic(input: $input) {
    id
  }
}`;

export const deleteMatchPlayer = `mutation deleteMatchPlayer($input: DeleteMatchPlayerInput!) {
  deleteMatchPlayer(input: $input) {
    id
  }
}`;

export const deletePlayerInjury = `mutation deletePlayerInjury($input: DeletePlayerInjuryInput!) {
  deletePlayerInjury(input: $input) {
    id
  }
}`;

export const deleteWeight = `mutation deleteWeight($input: DeleteWeightInput!) {
  deleteWeight(input: $input) {
    id
  }
}`;

export const updateClub = `mutation updateClub($input: UpdateClubInput!) {
  updateClub(input: $input) {
    names
    cities
    emails
    phones
    country_code
    logo
    official
  }
}`;

export const updateClubAdmin = `mutation updateClubAdmin($input: UpdateClubAdminInput!) {
  updateClubAdmin(input: $input) {
    first_name
    last_name
    email
    phone_code
    phone_number
    club_id
  }
}`;

export const updateMatch = `mutation updateMatch($input: UpdateMatchInput!) {
  updateMatch(input: $input) {
    away_team_name
    date
    home_team_name
    hour
    id
    stadium_address
    stadium_name
    week
    home_team {
      id
      club {
        id
        logo
        names
        official
      }
    }
    away_team {
      id
      club {
        id
        logo
        names
        official
      }
    }
  }
}`;

export const updateMatchPlayer = `mutation updateMatchPlayer($input: UpdateMatchPlayerInput!) {
  updateMatchPlayer(input: $input) {
    id
    captain
    match_id
    position
    user_id
  }
}`;

export const updateUser = `mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    first_name
    last_name
    email
    phone_country_code
    phone_dial_code
    phone_number
    nationality_code
    birthday
    height
    weight
    type
    gender
    photo_path
  }
}`;

export const updateUserClubContribution = `mutation updateUserClubContribution($input: UpdateUserClubContributionInput!) {
  updateUserClubContribution(input: $input) {
    id
    user_id
    club_id
    paid
    season
  }
}`;

export const updateUserClubDocument = `mutation updateUserClubDocument($input: UpdateUserClubDocumentInput!) {
  updateUserClubDocument(input: $input) {
    id
    user_id
    club_id
    license_path
    id_card_path
    health_questionnaire_path
    medical_certificate_path
    club_rules_path
    image_rights_form_path
  }
}`;

export const updateTactic = `mutation updateTactic($input: UpdateTacticInput!) {
  updateTactic(input: $input) {
    id
    title
    schema
    notes
  }
}`;

export const updateTeam = `mutation updateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
    name
    category
    gender
    club_id
    deleted
    deletion_date
  }
}`;

export const updateCompetitionTeam = `mutation updateCompetitionTeam($input: UpdateCompetitionTeamInput!) {
  updateCompetitionTeam(input: $input) {
    id
    deleted
    deletion_date
  }
}`;
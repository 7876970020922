import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

export const getSession = async() => {
  try {
    const session = await Auth.currentSession();

    return session;
  }
  catch(err) {
    //console.log(err)
    if (err !== 'No current user') {
      //console.log(err);
    }
  }
}

export const getUser = async() => {
  try {
    const user = await Auth.currentSession();

    return user;
  }
  catch(err) {
    //console.log(err)
  }
}

export const sessionHandler = async() => {
  try {
    const session = await getSession()
    const groups = session?.idToken?.payload['cognito:groups'];
    const userId = session?.idToken?.payload.sub;
    if(groups?.includes("ClubAdmins")) {
      const clubAdmin = await API.graphql({ query: queries.getClubAdmin, variables: { id: userId }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      const adminClubId = clubAdmin.data.getClubAdmin.club_id;
      if(adminClubId) {
        window.location.pathname = `/clubs/${adminClubId}`
        window.scrollTo(0, 0);
      } else {
        window.location.pathname = `/clubs/new`
        window.scrollTo(0, 0);
      }
    } else if(groups?.includes("Coaches")) {
      window.location.pathname = `/coaches/${userId}`
      window.scrollTo(0, 0);
    } else if(groups?.includes("Players")) {
      window.location.pathname = `/players/${userId}`
      window.scrollTo(0, 0);
    }
  }
  catch(err) {
    console.log(err)
  }
}

export const getUserGroups = async() => {
  try {
    const session = await getSession()
    const groups = session.idToken.payload['cognito:groups'];

    return groups;
  }
  catch(err) {
    console.log(err)
  }
}

export const getClubId = async() => {
  try {
    const session = await getSession()
    const userId = session.idToken.payload.sub;

    const clubAdmin = await API.graphql({ query: queries.getClubAdmin, variables: { id: userId }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    const adminClubId = clubAdmin?.data?.getClubAdmin?.club_id

    return adminClubId;
  }
  catch(err) {
    console.log(err)
  }
}
import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import Toolbar from '@mui/material/Toolbar'
import { SideMenu } from "../components/Menu/Menu.js"
import TopBar from "../components/TopBar/TopBar.js"
import { Profile as ClubProfile } from "./Clubs/Profile"
import { NewClub } from "./Clubs/NewClub"
import UserTeams from "./Teams/UserTeams";
import NotFound from "./NotFound"
import Home from "./Home/Home"
import SignIn from "./Auth/SignIn"
import SignUp from "./Auth/SignUp"
import NewPassword from "./Auth/NewPassword"
import VerificationCode from "./Auth/VerificationCode"
import { getSession } from "../utils/auth"
import * as queries from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'
import { useDispatch } from 'react-redux';
import { PrivateRoute, PublicRoute } from "../components/Route.js"
import ScrollToTop from "./ScrollToTop"
import AccountEdition from "./Account/Edition"
import PwdRecoveringInitiate from "./Recover/Initiate"
import PwdRecoveringCode from "./Recover/Code"
import PwdRecover from "./Recover/Password"
import AfterGame from "./Matches/AfterGame"
import PlayerGameSummary from "./Matches/PlayerGameSummary"
import PreGame from "./Matches/PreGame"
import Calendar from "./Calendar/Calendar"
import { Profile as CoachProfile } from "./Users/Profile"
import { Stats as TeamStats } from "./Teams/Stats"
import Invitations from "./Invitations/Invitations"
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions"
import Players from "./Teams/Players"
import Language from "./Settings/Language"
import Teams from "./Teams/Teams"
import Tactics from "./Teams/Tactics.js"
import TeamPlayer from "./Teams/TeamPlayer"
import TeamDocuments from "./Teams/TeamDocuments"
import Subscriptions from "./Subscriptions/Subscriptions"
import Plans from "./Subscriptions/Plans"
import Messages from "./Conversations/Messages.js"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { updateTeam } from '../store/reducers/user'

const COLOR_LIGHT = "#f4f4f4";
const COLOR_PRIMARY = "#45d69f";
const COLOR_PINK= "#e92163";
const HORIZONTAL_PADDING = '6%';
const TOP_BAR_HEIGHT = 56;
const VERTICAL_CONTENT_PADDING = 25;
const MENU_WIDTH = 250;

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    pink: {
      main: COLOR_PINK,
      light: "#f06d94",
      dark: "#a60e46"
    }
  },
});

const App = (props) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state?.user);

  const [isPwa, setIsPwa] = useState(false);

  useEffect(() => {  
    let subscription;

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsPwa(isStandalone);
  
    const fetchData = async () => {
      const session = await getSession();
      subscription = await getTeamUpdated();
    }
  
    fetchData();
  
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  }, [user]);
  
  const getTeamUpdated = async (isMounted) => {
    const clubId = user?.club?.id
    const teamId = user?.club?.team?.id

    if(clubId) {
      const filter = {
        club_id: { eq: clubId },
        deleted: { eq: false }
      }
  
      let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      teamsList = teamsList.data.listTeams.items;
  
      if(teamId && teamsList.length > 0) {
        API.graphql({
          query: subscriptions.onUpdateTeam,
          variables: {
            filter: {
              id: { eq: teamId }
            }
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async (eventData) => {
              const filter = {
                club_id: { eq: clubId },
                deleted: { eq: false }
              }
  
              let teamsList = await API.graphql({ query: queries.listTeams, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
              teamsList = teamsList.data.listTeams.items;
        
              if(teamsList.length > 0) {
                const teamsListOrdered = teamsList.sort((a, b) => {
                  const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
                  const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
                  return numberA - numberB;
                });
    
                dispatch(updateTeam({ id: teamsListOrdered[0].id, name: teamsListOrdered[0].name }));
              } else {
                dispatch(updateTeam({ id: null, name: null }));
              }
  
            },
            error: (error) => {
              console.error("Subscription error:", error);
            }
        });
      } else {
        API.graphql({
          query: subscriptions.onCreateTeam,
          variables: {
            filter: {
              club_id: { eq: clubId }
            }
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
            next: async (eventData) => {        
              const { id, name } = eventData.value.data.onCreateTeam;
              dispatch(updateTeam({ id, name }));
            },
            error: (error) => {
              console.error("Subscription error:", error);
            }
        });
      }
    } 
  };

  const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height: TOP_BAR_HEIGHT,
      backgroundColor: '#1A1919',
      paddingLeft: HORIZONTAL_PADDING,
      paddingRight: HORIZONTAL_PADDING
    },
    appContainer: {
      display: 'flex'
    },
    ligthContentZone: {
      backgroundColor: COLOR_LIGHT
    },
    contentWrapper: {
      width: '100%'
    },
    content: {
      flexGrow: 1,
      paddingTop: 0,
      paddingBottom: 0,
      paddingTop: 15,
      paddingBottom: 15,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: '3%',
        paddingRight: '3%'
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 30,
        paddingRight: 30
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 45,
        paddingRight: 45
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 60,
        paddingRight: 60
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 80,
        paddingRight: 80
      }
    },
    content2: {
      display: 'flex',
      flexGrow: 1,
      paddingTop: 0,
      paddingBottom: 0,
      paddingTop: 15,
      paddingBottom: 15
    },
    menuIcon: {
      marginRight: 8,
      width: 23,
      height: 23,
      fill: COLOR_LIGHT,
      stroke: COLOR_LIGHT
    },
    menuIconFocus: {
      marginRight: 8,
      width: 23,
      height: 23,
      fill: COLOR_PRIMARY,
      stroke: COLOR_PRIMARY
    }
  }));
  const classes = useStyles();

  // club suite à l'authent dans le token (appel backend)
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {isPwa && (
            <Route path="*" element={<Navigate to="/auth/sign_in" />} />
          )}
          <Route path="/" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content2}>
                  <Home />
                </div>
              </div>
            </PublicRoute>
          } />
          <Route path="/accounts/edit" element={
            <PrivateRoute>
              <TopBar btnMenu={true} btnCaret={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <AccountEdition />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/auth/new_password" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <NewPassword />
            </PublicRoute>
          } />
          <Route path="/auth/sign_in" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <SignIn />
            </PublicRoute>
          } />
          <Route path="/auth/sign_up" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <SignUp />
            </PublicRoute>
          } />
          <Route path="/auth/verification_code" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <VerificationCode />
            </PublicRoute>
          } />
          <Route path="/calendar_results" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Calendar />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Teams />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/messages" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <Messages />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/tactics" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Tactics />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/players" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Players />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/players/:playerId" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <SideMenu />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <TeamPlayer />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/teams/:teamId/stats" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <SideMenu />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <TeamStats />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/teams/:teamId/documents" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <SideMenu />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <TeamDocuments />
                </div>
              </div>
            </PrivateRoute>
          } />
          {user.groups.includes("Players") || user.groups.includes("Coaches") ?
            <Route exact path="/users/:id/teams" element={
              <PrivateRoute>
                <TopBar />
                <Toolbar />
                <div className={classes.appContainer}>
                  <SideMenu />
                  <div className={classes.contentWrapper}>
                    <div className={classes.content}>
                      <UserTeams />
                    </div>
                  </div>
                </div>
              </PrivateRoute>
            } />
            :
            <Route exact path="/clubs/:id/teams" element={
              <PrivateRoute>
                <TopBar />
                <Toolbar />
                <div className={classes.appContainer}>
                  <SideMenu />
                  <div className={classes.contentWrapper}>
                    <div className={classes.content}>
                      <UserTeams />
                    </div>
                  </div>
                </div>
              </PrivateRoute>
            } />          
          }
          <Route path="/clubs/new" element={
            <PrivateRoute>
              <TopBar btnSignIn={false} btnCaret={false} />
              <Toolbar />
              <NewClub />
            </PrivateRoute>
          } />
          <Route path="/clubs/:id" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <ClubProfile />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/invitations" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Invitations />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/coaches/:id" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <CoachProfile />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/pre_game" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <PreGame />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/after_game" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <AfterGame />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/after_game/players/:id" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <PlayerGameSummary />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/plans" element={
            <PublicRoute>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Plans />
                </div>
              </div>
            </PublicRoute>
          } />
          <Route path="/players/:id" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <CoachProfile />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/settings/language" element={
            <>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Language />
                </div>
              </div>
            </>
          } />
          <Route path="/recover/initiate" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <PwdRecoveringInitiate />
            </PublicRoute>
          } />
          <Route path="/recover/code" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <PwdRecoveringCode />
            </PublicRoute>
          } />
          <Route path="/recover/password" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <PwdRecover />
            </PublicRoute>
          } />
          <Route path="/subscriptions" element={
            <PrivateRoute>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Subscriptions />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/terms_and_conditions" element={
            <PublicRoute>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <TermsAndConditions />
                </div>
              </div>
            </PublicRoute>
          } />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
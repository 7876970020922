import React, { useEffect, useState } from 'react'
import {makeStyles} from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'
import LineUp from '../../components/LineUp/LineUp'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import * as queries from '../../graphql/queries'

const GameLineUp = ({currentSeason, onChangeLineUp, lineUp, onChangePlayersList, playersList, game}) => {
  const useStyles = makeStyles((theme) => ({
    lineUpContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }))

  const classes = useStyles()
  const userStored = useSelector(state => state?.user);

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const currentFormation = await getFormation(game)
      await getLineUp(game, currentFormation, currentSeason)
      setLoaded(true)
    }
  
    fetchData()
  }, [])

  const getFormation = async (game) => {
    let currentFormation = "4-3-3";
    if(game.home_team_formation) {
      currentFormation = game.home_team_formation
    } else if(game.away_team_formation) {
      currentFormation = game.away_team_formation
    } else if(lineUp.formation) {
      currentFormation = lineUp.formation
    }

    return currentFormation
  }

  const getLineUp = async (game, currentFormation, currentSeason) => {
    try {
      const matchPlayerFilter = {
        match_id: { eq: game.id }
      }

      const team = userStored?.club.team
      const userTeamFilter = {
        season: { eq: currentSeason },
        team_id: { eq: team.id },
        user_type: { eq: "P" },
        invite_status: { eq: 1 },
        end_date: { gt: game.date }
      }

      let matchPlayers = await API.graphql({
        query: queries.listMatchPlayers,
        variables: { filter: matchPlayerFilter, userTeamFilter: userTeamFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      matchPlayers = matchPlayers.data.listMatchPlayers.items.map(player => {
        const adaptedPlayer = {
          position_number: player.position,
          player: {
            id: player.user.id,
            first_name: player.user.first_name,
            last_name: player.user.last_name,
            photo_path: player.user.photo_path,
            position: player.user.teams.items[0].position
          }
        };
  
        if (adaptedPlayer.position_number < 12) {
          return { ...adaptedPlayer, line_up: true };
        } else if (adaptedPlayer.position_number === 12) {
          return { ...adaptedPlayer, bench_players: true };
        } else {
          return adaptedPlayer;
        }
      });

      //setInitialMatchPlayers(matchPlayers)
  
      const lineUpInitial = [
        { position: 1, player: {} },
        { position: 2, player: {} },
        { position: 3, player: {} },
        { position: 4, player: {} },
        { position: 5, player: {} },
        { position: 6, player: {} },
        { position: 7, player: {} },
        { position: 8, player: {} },
        { position: 9, player: {} },
        { position: 10, player: {} },
        { position: 11, player: {} }
      ];
  
      const gameLineUp = lineUpInitial.map((player) => {
        const matchingPlayer = matchPlayers.find((p) => p.position_number === player.position);
  
        player.start_at = 0;

        if (matchingPlayer) {
          return {
            ...player,
            player: {
              id: matchingPlayer.player.id,
              first_name: matchingPlayer.player.first_name,
              last_name: matchingPlayer.player.last_name,
              photo_path: matchingPlayer.player.photo_path,
              position: matchingPlayer.player.position
            }
          };
        } else {
          return player;
        }
      });

      const benchPlayers = matchPlayers.filter(player => player.bench_players);

      setLineUp({formation: currentFormation, line_up: gameLineUp, bench_players: benchPlayers });
    } catch (err) {
      console.log(err);
    }
  }

  const setLineUp = async(data) => {
    onChangeLineUp(data)
  }

  const setPlayersList = async(data) => {
    onChangePlayersList(data)
  }

  let CONTENT;

  if(loaded) {
    CONTENT =
      <LineUp
        currentSeason={currentSeason}
        onChange={setLineUp}
        lineUp={lineUp}
        onChangePlayersList={setPlayersList}
        playersList={playersList}
        editable={true}
      />
  } else {
    CONTENT = <div></div>
  }

  return(
    <Grid container spacing={2} className={classes.lineUpContainer}>
      {CONTENT}
    </Grid>
  )
}
  
export default GameLineUp
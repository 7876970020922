import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120

export const useStylesRecover = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3%',
      paddingRight: '3%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  titleZone: {
    width: '100%',
    marginBottom: 30
  },
  formBtn: {
    width: '100%',
    marginTop: 35
  },
  input: {
    marginBottom: 15
  },
  lastInput: {
    marginBottom: 0
  },
  formZone: {
    backgroundColor: '#1A1919',
    borderRadius: 10,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 2*20px)',
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
      padding: 30
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
      padding: 30
    },
    [theme.breakpoints.up('md')]: {
      width: 400,
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      width: 450,
      padding: 30
    }
  }
}));
import React, { useState, useEffect, useRef } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API, graphqlOperation } from 'aws-amplify';
import { useSelector } from 'react-redux'
import { sortASC } from "../../utils/sort";
import { ReactComponent as AssistIcon } from '../../assets/icons/assist.svg';
import { ReactComponent as BallIcon } from '../../assets/icons/ball.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import CButton from "../../components/Button";
import InputCode from '../../components/Input/InputCode'
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import { SearchBar } from '../../components/TextInput';
import { Menu, TabPanel } from '../../components/Menu/index';
import ProfilePicture from "../../components/ProfilePicture/index";
import CSelect from "../../components/Select";
import TextInput from '../../components/TextInput.js';
import user from '../../store/reducers/user';
import { getCurrentSeason } from "../../utils/season"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const matchTimes = Array.from({length: 90}, (_, i) => { return { "label": `${i+1}'`, "code": i+1 } } );

const searchPlayers = async (listPlayers, input) => {
  if (input.length < 1) {
    return listPlayers.slice(0, 6);
  } else {
    const searchTerms = input.toLowerCase().split(' ').filter(term => term.length > 0);

    return listPlayers.filter(user => {
      const userNames = [user.user.first_name.toLowerCase(), user.user.last_name.toLowerCase()];

      return searchTerms.every(term => {
        // Vérifiez si le terme commence par le nom ou le prénom
        const startsWithTerm = userNames.some(name => name.startsWith(term));

        // S'il commence par le terme ou si le terme a au moins 3 caractères et est inclus dans le nom, retournez vrai
        return startsWithTerm || (term.length >= 3 && userNames.some(name => name.includes(term)));
      });
    });
  }
};

const getTeamPlayers = async(userStored) => {
  try {
    const currentSeason = await getCurrentSeason()

    const teamId = userStored?.club?.team?.id

    const userFilter = {
      invite_status: { eq: 1 },
      user_type: { eq: "P" },
      season: { eq: currentSeason }
    }

    let teamPlayers = await API.graphql({
      query: queries.getTeamPlayers,
      variables: {
        id: teamId,
        userFilter: userFilter
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    teamPlayers = teamPlayers.data.getTeam.users.items

    return teamPlayers;
  }
  
  catch(err) { console.log(err) }
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  modalSearch: {
    width: '100%'
  },
  modalSearchBarWrapper: {
    paddingLeft: 5,
    paddingRight: 5
  },
  modalSearchResults: {
    marginTop: 20
  },
  modalSearchResult: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    "&&:hover": {
      borderRadius: 10,
      backgroundColor: 'rgba(69, 214, 159, 0.07)',
      cursor: 'pointer'
    }
  },
  modalSearchResultImg: {
    height: 40,
    marginRight: 10
  },
  modalTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalPlayerPhoto: {
    width: 40,
    height: 40,
    borderRadius: 100,
    border: '1px solid',
    marginRight: 10
  },
  modalSelectedPlayerPhoto: {
    width: 80,
    height: 80,
    borderRadius: 100,
    backgroundColor: COLOR_LIGHT,
    marginBottom: 15
  },
  modalSelectedPlayer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  modalInfo: {
    marginTop: 30,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  modalValidationBtn: {
    marginTop: 'auto',
    width: 200
  },
  modalCardsZone: {
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  modalCardZone: {
    all: 'unset',
    cursor: 'pointer',
    width: 30+4,
    height: 30+8,
    borderRadius: 7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  modalCardZoneSelected: {
    backgroundColor: COLOR_PRIMARY
  },
  cardIcon: {
    width: 30,
    height: 30
  },
  yellowCard: {
    fill: COLOR_YELLOW
  },
  redCard: {
    fill: COLOR_RED
  },
  selector: {
    width: 150
  },
  icon: {
    width: 23,
    height: 23,
    alignItems: 'center',
    textAlign: 'center',
    fill: COLOR_LIGHT,
    marginRight: 8
  },
  goalsScorerZone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  goalLabel: {
    marginLeft: 10
  },
  goalsAssiterZone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15
  },
  goalsAssiterZone2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
    marginBottom: 10
  }
}));

const GoalDescriptionModal = ({goalDescription, goalNumber}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [selectedScorer, setSelectedScorer] = useState(null);
  const [selectedAssister, setSelectedAssister] = useState(null);
  const [selectedGameTime, setSelectedGameTime] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [listPlayers, setListPlayers] = useState([]);
  const [searchValue, setSearchValue] = useState('');


  useEffect(() => {  
    const fetchData = async () => {
      const players = await getTeamPlayers(userStored);
      setAllPlayers(players)
      setListPlayers(players.slice(0, 6))
    }
  
    fetchData()
  }, [])

  const selectedTimeCb = (data) => {
    setSelectedGameTime(data)
  }

  const onChangeSearch = async(data) => {
    setSearchValue(data);
    const players = await searchPlayers(allPlayers, data)
    setListPlayers([]) // Don't remove
    setListPlayers(players)
  }

  const selectScorer = (player) => {
    setSelectedScorer(player);
    setSearchValue('');
    setListPlayers(allPlayers.slice(0, 6))
  };

  const selectAssister = (player) => {
    setSelectedAssister(player);
    setSearchValue('');
    setListPlayers(allPlayers.slice(0, 6))
  };

  const submit = () => {
    const data = {
      scorer: {
        id: selectedScorer.user.id,
        first_name: selectedScorer.user.first_name,
        last_name: selectedScorer.user.last_name
      },
      assister: {
        id: selectedAssister.user.id,
        first_name: selectedAssister.user.first_name,
        last_name: selectedAssister.user.last_name
      },
      time: selectedGameTime.code
    }
    goalDescription(data)
  }
  
  let CONTENT;
  if(selectedScorer === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar
          placeholder={`${t('after_game.2.modals.0.inputs.0.label')}`}
          value={searchValue}
          inputCallback={onChangeSearch}
        />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => selectScorer(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light" label={`${player.user.first_name} ${player.user.last_name}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else if(selectedAssister === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar
          placeholder={`${t('after_game.2.modals.1.inputs.0.label')}`}
          value={searchValue}
          inputCallback={onChangeSearch}
        />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => selectAssister(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light" label={`${player.user.first_name} ${player.user.last_name}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else {
    CONTENT =
    <>
      <div className={classes.modalTitle}>
        <CText level="h2" fontWeight={700} color="primary">{t('after_game.2.modals.2.title')}{goalNumber}</CText>
      </div>
      <div className={classes.modalInfo}>
        <div>
          <div className={classes.goalsScorerZone}>
            <CText level="p1" color="light">
              <span className={classes.icon}>
                <BallIcon />
              </span>
              {selectedScorer.user.first_name} {selectedScorer.user.last_name}
            </CText>
          </div>
          <div className={classes.goalsAssiterZone2}>
            <CText level="p1" color="light">
              <span className={classes.icon}>
                <AssistIcon />
              </span>
              {selectedAssister.user.first_name} {selectedAssister.user.last_name}
            </CText>
          </div>
        </div>
        <div className={classes.selector}>
          <CSelect
            label={t('common.game_time')}
            selectCallback={selectedTimeCb}
            data={matchTimes}
            value={selectedGameTime}
          />
        </div>
      </div>

      <CButton
        className={classes.modalValidationBtn}
        label={t('button.confirm')}
        height={38}
        type="contained"
        onClick={() => submit()}
      />
    </>
  }

  return (
    <div className={classes.modalContainer}>
      {CONTENT}
    </div>
  );
}

const CardModal = ({cardDescription}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedGameTime, setSelectedGameTime] = useState(null);

  const [card, setCard] = useState('Y');
  const [allPlayers, setAllPlayers] = useState([]);
  const [listPlayers, setListPlayers] = useState([]);

  useEffect(() => {  
    const fetchData = async () => {
      const players = await getTeamPlayers(userStored);
      setAllPlayers(players)
      setListPlayers(players.slice(0, 6))
    }
  
    fetchData()
  }, [])

  const onChangeSearchPlayer = async(data) => {
    const players = await searchPlayers(listPlayers, data)
    setListPlayers([]) // Don't remove
    setListPlayers(players)
  }
  
  const selectedTimeCb = (data) => {
    setSelectedGameTime(data)
  }

  const submit = () => {
    const cardInfo = {
      player: {
        id: selectedPlayer.user.id,
        first_name: selectedPlayer.user.first_name,
        last_name: selectedPlayer.user.last_name
      },
      type: card,
      time: selectedGameTime.code
    }

    cardDescription(cardInfo)
  }

  let CONTENT;
  if(selectedPlayer === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar placeholder={`${t('search_bar.player.label')}`} inputCallback={onChangeSearchPlayer} />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => setSelectedPlayer(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light">{player.user.first_name} {player.user.last_name}</CText>
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else {
    CONTENT =
    <div className={classes.modalSelectedPlayer}>
      <CText level="h2" fontWeight={700} color="primary">{t('after_game.2.modals.3.title')}</CText>
      <div className={classes.modalInfo}>
        <ProfilePicture className={classes.modalSelectedPlayerPhoto} src={selectedPlayer.user.photo_path} />
        <CText level="h3" fontWeight={700} color="light">{selectedPlayer.user.first_name} {selectedPlayer.user.last_name}</CText>
        <div style= {{display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop: 20}}>
          <div className={classes.modalCardsZone}>
          <div
            id="yellow-card"
            className={[classes.modalCardZone, card === "Y" ? classes.modalCardZoneSelected : null].join(" ")}
            onClick={() => setCard('Y')}
          >
            <CardIcon className={[classes.cardIcon, classes.yellowCard].join(" ")} />
          </div>
          <div
            id="red-card"
            className={[classes.modalCardZone, card === "R" ? classes.modalCardZoneSelected : null].join(" ")}
            onClick={() => setCard('R')}
          >
            <CardIcon className={[classes.cardIcon, classes.redCard].join(" ")} />
          </div>
          </div>
          <CSelect
            className={classes.selector}
            label={t('common.game_time')}
            selectCallback={selectedTimeCb}
            data={matchTimes}
            value={selectedGameTime}
          />
        </div>
      </div>

      <CButton
        className={classes.modalValidationBtn}
        label={t('button.confirm')}
        height={38}
        type="contained"
        onClick={() => submit()}
      />
    </div>
  }

  return (
    <div className={classes.modalContainer}>
      {CONTENT}
    </div>
  );
}

const Hightlights = ({goalsNumber, onChangeGoals, goals, onChangeCards, cards}) => {
  const useStyles = makeStyles((theme) => ({
    highlightsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    highlightsInfo: {
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 10,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        width: 350,
      },
      [theme.breakpoints.up('sm')]: {
        width: 450,
      },
      [theme.breakpoints.up('md')]: {
        width: 550,
      },
      [theme.breakpoints.up('lg')]: {
        width: 600
      }
    },
    highlightZone: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: COLOR_DARK,
      borderRadius: 10,
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(95%)'
      },
      marginBottom: 10
    },
    highlightsInfoTitleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative'
    },
    highlightsInfoTitle: {
      marginBottom: 20
    },
    highlightsInfoBtnZone: {
      position: 'absolute', right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    icon: {
      width: 23,
      height: 23,
      alignItems: 'center',
      textAlign: 'center',
      fill: COLOR_LIGHT,
      marginRight: 8
    },
    goalsScorerZone: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10
    },
    goalsAssiterZone: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 15
    },
    highligthTimeZone: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      marginBottom: -20
    },
    goalsTime: {
      padding: 2,
      backgroundColor: COLOR_PRIMARY,
      color: COLOR_DARK,
      width: 30,
      borderRadius: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    btnContainer: {
      width: 180,
      marginTop: 30
    },
    btnContainer: {
      width: 180,
      marginTop: 30
    },
    yellowCard: {
      fill: COLOR_YELLOW
    },
    redCard: {
      fill: COLOR_RED
    },
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [goalIndex, setGoalIndex] = useState(null);
  const [newGoals, setNewGoals] = useState(Array.from({ length: goalsNumber }, () => ({ scorer: "" })));
  const [cardIndex, setCardIndex] = useState(null);
  const [newCards, setNewCards] = useState([]);
  const [modalOpen, setModalOpen] = useState({value: false})

  useEffect(() => {
    const fetchData = async () => {
      if (goals && goals.length > 0) {
        const updatedGoals = Array.from({ length: goalsNumber }, (_, index) => goals[index] || { scorer: "" });
        setNewGoals(updatedGoals);
        onChangeGoals(updatedGoals);
      }
      if(cards.length > 0) setNewCards(cards);
    }
  
    fetchData()
  }, [])

  const goalDescriptionClick = (index) => {
    setGoalIndex(index)
    setModalOpen({type: 0, value: true});
  }

  const setGoalDescription = async (goal) => {
    setModalOpen({ value: false });
  
    const updateGoals = () => {
      const updatedGoals = [...newGoals];
      updatedGoals[goalIndex] = goal;
      return updatedGoals;
    };
  
    setNewGoals(updateGoals());
    onChangeGoals(updateGoals());
  };

  const addCardClick = () => {
    setModalOpen({type: 1, value: true});
  }

  const updateCardClick = (index) => {
    setCardIndex(index)
    setModalOpen({type: 1, value: true})
  }

  const setCardDescription = (card) => {
    setModalOpen({ value: false });
  
    const updateCards = (prevCards) => {
      const updatedCards = [...prevCards];
      if (cardIndex !== null) {
        updatedCards[cardIndex] = card;
      } else {
        updatedCards.push(card);
      }
      return updatedCards;
    };
  
    setNewCards((prevCards) => updateCards(prevCards));
    setCardIndex(null);
    onChangeCards(updateCards(newCards))
  };

  let MODAL_CONTENT;
  switch (modalOpen.type) {
    case 0:
      MODAL_CONTENT = <GoalDescriptionModal goalDescription={setGoalDescription} goalNumber={goalIndex+1} />
      break;
    case 1:
      MODAL_CONTENT = <CardModal cardDescription={setCardDescription}/>
      break;
  }

  return (
    <Grid container spacing={2} className={classes.highlightsContainer}>
      <Grid item key={"highlights-goals"}>
        <div className={classes.highlightsInfo}>
          <CText className={classes.highlightsInfoTitle} level="h3" color="primary">{t('after_game.2.labels.0.label')}</CText>
          { newGoals.map((goal, index) => {
            return (
            <div key={`highlights-goals-${index}`} className={classes.highlightZone} onClick={() => goalDescriptionClick(index)}>
            {goal?.scorer ?
              <>
                <div className={classes.highligthTimeZone}>
                  <CText className={classes.goalsTime} level="p3" fontWeight="700">{goal.time}'</CText>
                </div>
                <div className={classes.goalsScorerZone}>
                  <CText level="p1" color="light">
                    <span className={classes.icon}>
                      <BallIcon />
                    </span>
                    {goal?.scorer?.first_name} {goal?.scorer?.last_name}
                  </CText>
                </div>
                <div className={classes.goalsAssiterZone}>
                  <CText level="p1" color="light">
                    <span className={classes.icon}>
                      <AssistIcon />
                    </span>
                    {goal.assister.first_name} {goal.assister.last_name}
                  </CText>
                </div>
              </>
              :
              <CText level="p1" color="light">
                <span className={classes.icon}>
                  <BallIcon />
                </span>
                {t('after_game.2.labels.2.label')}{index+1}
              </CText>
            }
            </div>
          )})}
        </div>
      </Grid>
      <Grid item key={"highlights-cards"}>
        <div className={classes.highlightsInfo}>
          <div className={classes.highlightsInfoTitleWrapper}>
            <CText className={classes.highlightsInfoTitle} level="h3" color="primary">{t('after_game.2.labels.1.label')} </CText>
            <div className={classes.highlightsInfoBtnZone}>
              <CButton
                label={t('pre_game.buttons.1')}
                height={26}
                size={14}
                type="contained"
                onClick={() => addCardClick()}
              />
            </div>
          </div>
          {newCards.map((card, index) => { return (    
            <div className={classes.highlightZone} onClick={() => updateCardClick(index)} key={index}>
              <div className={classes.highligthTimeZone}>
                <CText className={classes.goalsTime} level="p3" fontWeight="700">{card.time}'</CText>
              </div>
              <CText level="p1" color="light">
                <CardIcon className={[classes.icon, card.type === 'Y' ? classes.yellowCard : classes.redCard].join(" ")} />
                {card.player.first_name} {card.player.last_name}
              </CText>
            </div>
          )})}
        </div>
      </Grid>
      <CModal
        open={modalOpen.value}
        onClose={() => setModalOpen({value: false})}
        content={MODAL_CONTENT}
      >
      </CModal>
    </Grid>
  );
}

export default Hightlights;
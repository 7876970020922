import * as queries from '../graphql/queries'
import { API } from 'aws-amplify'

export const getCurrentSeason = async () => {
  try {
      const searchSeasonsResp = await API.graphql({ query: queries.listSeasons, authMode: 'AMAZON_COGNITO_USER_POOLS'});

      if (searchSeasonsResp && searchSeasonsResp.data && searchSeasonsResp.data.listSeasons && searchSeasonsResp.data.listSeasons.items) {
          const sortedSeasons = searchSeasonsResp.data.listSeasons.items.sort((a, b) => b.name.localeCompare(a.name));

          return sortedSeasons[0].name;
      } else {
          throw new Error('Aucune saison trouvée.');
      }
  } catch (error) {
      console.error('Erreur lors de la récupération de la saison actuelle:', error);
      throw error;
  }
};
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useStyles } from './Footer.styles.js';
import { CText } from "../../components/Text";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={classes.footer}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <CText className={classes.footerTitle} color="light" level="p1">
            <strong>{t('footer.0.title')}</strong>
          </CText>
          <CText className={classes.footerText} color="light" level="p2">
            <a href="mailto:contact@footballconnexion.com">{t('footer.0.labels.0')}</a>
          </CText>
        </Grid>

        <Grid item xs={12} sm={3}>
          <CText className={classes.footerTitle} color="light" level="p1">
            <strong>{t('footer.1.title')}</strong>
          </CText>
          <CText
            className={classes.footerText}
            color="light"
            level="p2"
          >
            <a onClick={() => navigate('/terms_and_conditions')}>{t('footer.1.labels.0')}</a>
          </CText>
          <CText
            className={classes.footerText}
            color="light"
            level="p2"
          >
            <a onClick={() => navigate('/plans')}>{t('footer.1.labels.1')}</a>
          </CText>
        </Grid>

        <Grid item xs={12} sm={3}>
          <CText className={classes.footerTitle} color="light" level="p1">
            <strong>{t('footer.2.title')}</strong>
          </CText>
          <CText
            className={classes.footerText}
            color="light"
            level="p2"
          >
            <a onClick={() => navigate('/settings/language')}>{t('footer.2.labels.0')}</a>
          </CText>
        </Grid>
        
        {/* Colonne 4: (Réservée ou autre information) */}
        <Grid item xs={12} sm={3}>
          <CText className={classes.footerText} color="light" level="p2">
            {/* Ajoutez ici toute autre information ou laissez cette colonne vide */}
          </CText>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
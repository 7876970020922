export const updateTeamNameInPath = (path, currentTeamName, navigate) => {
  const regex = /\/teams\/([^/]+)(\/.*)?/;
  const match = path.match(regex);

  if (match) {
    const urlTeamName = decodeURIComponent(match[1]);
    const afterTeamName = match[2] || '';
    const encodedTeamName = encodeURIComponent(currentTeamName);

    if (urlTeamName !== currentTeamName) {
      const newPath = `/teams/${encodedTeamName}${afterTeamName}`;
      navigate(newPath);
    }
  }
};
import React, { useState, useEffect } from 'react'
import TextInput from '../../components/TextInput'
import { useTranslation } from "react-i18next"
import { Auth } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import MobilePhoneInput from "../../components/Input/MobilePhone"
import { API, graphqlOperation } from 'aws-amplify'
import { CDatePicker } from '../../components/DatePicker'
import CModal from "../../components/Modal"
import CButton from "../../components/Button"

import { ClubPhoto } from "../../components/Photos/ClubPhoto"
import ClubPhotoModal from '../../components/ProfilePicture/clubModal'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'

import { useDispatch } from 'react-redux'
import { useStyles } from './styles';
import { useSelector } from 'react-redux'

const COLOR_PRIMARY = '#45d69f';
const COLOR_PINK= "#e92163";

const ClubInfo = ({ updateInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector((state) => state?.user);

  const [club, setClub] = useState({});
  const [cities, setCities] = useState([{ name: "" }]);
  const [modifiedCities, setModifiedCities] = useState(false);
  const [names, setNames] = useState([{ name: "" }]);
  const [modifiedNames, setModifiedNames] = useState(false);
  const [emails, setEmails] = useState([{ email: "" }]);
  const [modifiedEmails, setModifiedEmails] = useState(false);
  const [phones, setPhones] = useState([{ number: "" }]);
  const [modifiedPhones, setModifiedPhones] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const clubAdmin = await getClubAdminInfo();
      const clubId = clubAdmin?.club_id;

      await getClubInfo(clubAdmin, clubId);
      setIsLoaded(true)
    }
  
    fetchData();
  }, [userStored])

  const getClubAdminInfo = async () => {
    try {
      const userIdStored = userStored.info.id
      let clubAdmin = await API.graphql({ query: queries.getClubAdmin, variables: { id: userIdStored }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      clubAdmin = clubAdmin?.data?.getClubAdmin

      return clubAdmin
    }
    catch (err) { console.log(err) }
  }

  const getClubInfo = async (clubAdmin, clubId) => {
    try {
      let getClubResp = await API.graphql({ query: queries.getClub, variables: { id: clubId }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      getClubResp = getClubResp?.data?.getClub
      //dispatch(setPhotoPath({ "photoPath": getClubResp.logo }))

      setClub(getClubResp)

      if (getClubResp && getClubResp.names) {
        const parsedNames = getClubResp.names.map(name => JSON.parse(name));
        setNames(parsedNames);
      }
      if (getClubResp && getClubResp.cities) {
        const parsedCities = getClubResp.cities.map(name => JSON.parse(name));
        setCities(parsedCities);
      }
      if (getClubResp && getClubResp.emails) {
        const parsedEmails = getClubResp.emails.map(email => JSON.parse(email));
        setEmails(parsedEmails);
      }
      if (getClubResp && getClubResp.phones) {
        const parsedPhones = getClubResp.phones.map(phone => JSON.parse(phone));
        setPhones(parsedPhones);
      }
    }
    catch (err) { console.log(err) }
  }

  /********* Handle Names *********/
  const addNames = () => {
    setNames([...names, {name: ""}]);
    setModifiedNames(true);
  };
  const removeNames = (index) => {
    let newNames = [...names];
    newNames.splice(index, 1);
    setNames(newNames);
    setModifiedNames(true);
  };
  const namesCb = (data, index) =>{ 
    let newNames = [...names];
    newNames[index] = {name: data};
    setNames(newNames);
    setModifiedNames(true);
  };

  /********* Handle Cities *********/
  const addCities = () => {
    setCities([...cities, {name: ""}]);
    setModifiedCities(true);
  }; 
  const removeCities = (index) => {
    let newCities = [...cities];
    newCities.splice(index, 1);
    setCities(newCities);
    setModifiedCities(true);
  };
  const citiesCb = (data, index) =>{ 
    let newCities = [...cities];
    newCities[index] = {name: data};
    setCities(newCities);
    setModifiedCities(true);
  };

  /********* Handle Emails *********/
  const addEmails = () => {
    setEmails([...emails, { email: "" }]);
    setModifiedEmails(true);
  }; 
  
  const removeEmails = (index) => {
    let newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
    setModifiedEmails(true);
  };
  
  const emailsCb = (data, index) => { 
    let newEmails = [...emails];
    newEmails[index] = { email: data };
    setEmails(newEmails);
    setModifiedEmails(true);
  };

  /********* Handle Phones *********/
  const addPhones = () => {
    setPhones([...phones, { number: "" } ]);
    setModifiedPhones(true);
  }; 
  const removePhones = (index) => {
    let newPhones = [...phones];
    newPhones.splice(index, 1);
    setPhones(newPhones);
    setModifiedPhones(true);
  };
  const phonesCb = (data, index) => {
    let newPhones = [...phones];
    newPhones[index] = { number: data.phoneNumber, dial_code: data.dialCode, country_code: data.countryCode };

    setPhones(newPhones);
    setModifiedPhones(true);
  };

  const onClickSave = async () => {
    // Your logic to update the user
    const updatedClub = {
      id: club.id
    };

    if (modifiedNames && names && names !== club.names) {
      const filteredNames = names.filter(name => name.name !== "" && name.name !== null);
      const stringifiedNames = filteredNames.map(name => JSON.stringify(name));
      updatedClub.names = stringifiedNames;
    }

    if (modifiedCities && cities && cities !== club.cities) {
      const filteredCities = cities.filter(city => city.name !== "" && city.name !== null);
      const stringifiedCities = filteredCities.map(name => JSON.stringify(name));
      updatedClub.cities = stringifiedCities;
    }

    if (modifiedEmails && emails && emails !== club.emails) {
      const filteredEmails = emails.filter(email => email.email !== "" && email.email !== null);
      const stringifiedEmails = filteredEmails.map(email => JSON.stringify(email));
      updatedClub.emails = stringifiedEmails;
    }

    if (modifiedPhones && phones && phones !== club.phones) {
      const filteredPhones = phones.filter(phone => phone.number !== "" && phone.number !== null);
      const stringifiedPhones = filteredPhones.map(phone => JSON.stringify(phone));
      updatedClub.phones = stringifiedPhones;
    }

    if (Object.keys(updatedClub).length > 1) {
      const res = await API.graphql(graphqlOperation(mutations.updateClub, {
        input: updatedClub
      }));

      setModifiedNames(false);
      setModifiedCities(false);
      setModifiedEmails(false);
      setModifiedPhones(false);
    }
  };

  return (
    <>
      <div className={classes.topButtonsContainer}>
        <CButton
          className={classes.topButton}
          label={t('edit_account.buttons.0.label')}
          type="contained"
          color={COLOR_PRIMARY}
          size={14}
          loader={true}
          onClick={async () => await onClickSave()}
        />
      </div>

      {isLoaded === true ?
        <Grid container spacing={1} alignItems="flex-start" className={classes.content}>
          <Grid container item xs={12} md={3}>
            <ClubPhoto
              className={classes.teamLogo}
              onClick={() => setModalOpen({type: "clubPhoto", value: true})}
              owner={true}
            />
          </Grid>
          <Grid container item xs={12} md={9}>
            <div className={classes.formZone}>
              <div className={classes.multiInput}>
                {names.map((elmt, index) => (
                  <div key={index} className={[classes.input, classes.inputInline].join(" ")}>
                    <TextInput
                      label={t('edit_account.inputs.5.label') + `${index+1}`}
                      defaultValue={elmt.name}
                      inputCallback={(data) => namesCb(data, index)}
                    />
                    { index > 0 ?
                      <CButton
                        className={classes.inputDeleteBtn}
                        label={<TrashIcon className={classes.inputDeleteBtnIcon} />}
                        type="contained"
                        color={COLOR_PINK}
                        size={12}
                        onClick={() => removeNames(index)}
                      />
                      : null
                    }
                  </div>
                ))}
                <div className={classes.inputAddBtnWrapper}>
                  <CButton
                    className={classes.inputAddBtn}
                    label={t('button.add')}
                    type="contained"
                    color={COLOR_PRIMARY}
                    maxWidth={80}
                    size={12}
                    onClick={() => addNames()}
                  />
                </div>
              </div>
              <div className={classes.multiInput}>
                {cities.map((elmt, index) => (
                  <div key={index} className={[classes.input, classes.inputInline].join(" ")}>
                    <TextInput
                      label={t('edit_account.inputs.6.label') + `${index+1}`}
                      defaultValue={elmt.name}
                      inputCallback={(data) => citiesCb(data, index)}
                    />
                    { index > 0 ?
                      <CButton
                        className={classes.inputDeleteBtn}
                        label={<TrashIcon className={classes.inputDeleteBtnIcon} />}
                        type="contained"
                        color={COLOR_PINK}
                        size={12}
                        onClick={() => removeCities(index)}
                      />
                      : null
                    }
                  </div>
                ))}
                <div className={classes.inputAddBtnWrapper}>
                  <CButton
                    className={classes.inputAddBtn}
                    label={t('button.add')}
                    type="contained"
                    color={COLOR_PRIMARY}
                    maxWidth={80}
                    size={12}
                    onClick={() => addCities()}
                  />
                </div>
              </div>
              <div className={classes.multiInput}>
                {emails.map((elmt, index) => (
                  <div key={index} className={[classes.input, classes.inputInline].join(" ")}>
                    <TextInput
                      label={t('edit_account.inputs.7.label') + `${index + 1}`}
                      defaultValue={elmt.email}
                      inputCallback={(data) => emailsCb(data, index)}
                    />
                    {index > 0 ?
                      <CButton
                        className={classes.inputDeleteBtn}
                        label={<TrashIcon className={classes.inputDeleteBtnIcon} />}
                        type="contained"
                        color={COLOR_PINK}
                        size={12}
                        onClick={() => removeEmails(index)}
                      />
                      : null
                    }
                  </div>
                ))}
                <div className={classes.inputAddBtnWrapper}>
                  <CButton
                    className={classes.inputAddBtn}
                    label={t('button.add')}
                    type="contained"
                    color={COLOR_PRIMARY}
                    maxWidth={80}
                    size={12}
                    onClick={() => addEmails()}
                  />
                </div>
              </div>
              <div className={classes.multiInput}>
                {phones.map((elmt, index) => (
                  <div key={index} className={[classes.input, classes.inputInline].join(" ")}>
                    <MobilePhoneInput
                      initialCountryCode={elmt?.country_code}
                      initialPhoneNumber={elmt?.number}
                      onChange={(data) => phonesCb(data, index)}
                    />
                    { index > 0 ?
                      <CButton
                        className={classes.inputDeleteBtn}
                        label={<TrashIcon className={classes.inputDeleteBtnIcon} />}
                        type="contained"
                        color={COLOR_PINK}
                        size={12}
                        onClick={() => removePhones(index)}
                      />
                      : null
                    }
                  </div>
                ))}
                <div className={classes.inputAddBtnWrapper}>
                  <CButton
                    className={classes.inputAddBtn}
                    label={t('button.add')}
                    type="contained"
                    color={COLOR_PRIMARY}
                    maxWidth={80}
                    size={12}
                    onClick={() => addPhones()}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid> : null
      }
      <CModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={<ClubPhotoModal modalOpenCallback={(data) => setModalOpen(data)} />}
      >
      </CModal>
    </>
  );
};

export default ClubInfo;
import React, { useEffect, useState, useRef } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import { API } from 'aws-amplify';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import ProfilePicture from "../../components/ProfilePicture/index";
import { signOut } from '../../store/reducers/user';
import { CText } from "../Text.js";
import CButton from "../Button.js";
import { Menu } from "../../components/Menu/Menu.js";
import appLogo from "../../assets/images/app_logo.png";
import { CSSTransition } from 'react-transition-group';
import * as queries from '../../graphql/queries';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as RightChevronIcon } from '../../assets/icons/right-chevron.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as LanguageIcon } from '../../assets/icons/language.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as CaretIcon } from '../../assets/icons/caret.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import './style.css';
import { useStylesTopBar } from './topBar.styles.js';
import { Troubleshoot } from '@mui/icons-material';

const COLOR_LIGHT = "#f4f4f4";
const COLOR_DARK = "#242834";

/******* Note ********/
/******* 
  1 - Passer tout ce qu'il y a dans style.css en JSX
*******/

const NavItem = (props) => {
  const classes = useStylesTopBar();

  const ref = useRef(null);

  const handleClick = () => {
    props.dropdownOpenCallback();
    if (props.open) {
      ref.current.blur();
    }
  }

  return (
    <li className="nav-item">
      <a className={["icon-button", props.open ? classes.iconBtnFocused : null].join(' ')} onClick={() => handleClick()} ref={ref}>
        {props.icon}
      </a>
      {props.navItemOpen && props.children}
    </li>
  );
}

const DropdownSettings = (props) => {
  const classes = useStylesTopBar();

  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current.firstChild.offsetHeight);
    
    const handleClickOutside = (event) => {
      if(!event.target.closest("a") || !event.target.closest("a").classList.contains('icon-button')) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          props.dropdownOpenCallback();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])

  const calcHeight = (el) => {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  const signOutClick = async() => {
    try {
      Auth.signOut();
      navigate(`/`, { replace: true });
      window.scrollTo(0, 0);
      props.dropdownOpenCallback();
      await dispatch(signOut());
    } catch (err) {

    }
  }

  const DropdownItem = (props) => {
    return (
      <a className="menu-item" onClick={props.onClick}>
        <span className="icon-left">{props.leftIcon}</span>
          {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }

  const navigateTo = (path) => {
    const queryParams = location.search;
    navigate(path, {state: { prevUrl: window.location.pathname + queryParams }})
    props.dropdownOpenCallback()
  }

  return (
    <div className={classes.dropdown} style={{ height: menuHeight }} ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          <DropdownItem
            onClick={() => navigateTo("/accounts/edit")}
            leftIcon={<ProfilePicture className={classes.playerPhoto} owner={true} updatePhoto={false} />}
          >
            {t('account_menu.menus.0.label')}
          </DropdownItem>
          <DropdownItem
            leftIcon={<SettingsIcon />}
            rightIcon={<RightChevronIcon fill={COLOR_LIGHT} />}
            onClick={() =>  setActiveMenu("settings")}
          >
            {t('account_menu.menus.1.label')}
          </DropdownItem>
          <DropdownItem
            leftIcon={<LogoutIcon fill={COLOR_LIGHT}  />}
            onClick={() => signOutClick()}
          >
            {t('account_menu.menus.2.label')}
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'settings'}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          <DropdownItem
            onClick={ () =>  setActiveMenu("main")}
            leftIcon={<LeftArrowIcon />}
          >
            <h2>{t('account_menu.menus.1.label')}</h2>
          </DropdownItem>
          <DropdownItem leftIcon={<SettingsIcon />}>{t('account_menu.menus.1.submenus.0.label')}</DropdownItem>
          <DropdownItem leftIcon={<LanguageIcon />} onClick={() => navigateTo("/settings/language")}>{t('account_menu.menus.1.submenus.1.label')}</DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

const DropdownTopMenu = (props) => {
  const classes = useStylesTopBar()
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current.firstChild.offsetHeight);
    
    const handleClickOutside = (event) => {
      if(!event.target.closest("a") || !event.target.closest("a").classList.contains('icon-button')) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          props.dropdownOpenCallback();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])
  
  const dropdownTopMenuOpenCB = () => {
    props.dropdownOpenCallback();
  }
  
  return (
    <div className={classes.dropdown} ref={dropdownRef}>
      <div className="menu-primary">
        <Menu dropdownOpenCallback={dropdownTopMenuOpenCB} />
      </div>
    </div>
  );
}

const TopBar = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector(state => state?.user?.isAuthenticated);
  const stateStore = useSelector(state => state);
  const userStored = useSelector(state => state?.user);

  const classes = useStylesTopBar()

  const [team, setTeam] = useState({});
  const [isLoading, setIsLoading] = useState(Troubleshoot);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [showMenuBtn, setShowMenuBtn] = useState(false);

  const getTeam = async(teamId) => {
    const getTeamResp = await API.graphql({
      query: queries.getTeam,
      variables: { 
        id: teamId
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    setTeam(getTeamResp.data.getTeam);
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    if(props.btnMenu === true) {
      setShowMenuBtn(true)
    } else {
      if(windowSize[0] < 960) {
        setShowMenuBtn(true)
      } else {
        setShowMenuBtn(false)
      }
    }

    const fetchData = async () => {
      if(stateStore?.user && userStored.isAuthenticated && userStored?.club?.team?.id) { await getTeam(userStored?.club?.team?.id) }

      setIsLoading(false);
    }

    fetchData();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [userStored, windowSize, props.btnMenu]);

  const [dropdownTopMenuOpen, setDropdownMenuOpen] = useState(false);
  const [dropdownSettingsOpen, setDropdownSettingsOpen] = useState(false);

  const dropdownTopMenuOpenCB = () => {
    setDropdownSettingsOpen(false);
    setDropdownMenuOpen(!dropdownTopMenuOpen);
  };

  const dropdownSettingsOpenCB = () => {
    setDropdownMenuOpen(false);
    setDropdownSettingsOpen(!dropdownSettingsOpen);
  };

  const signIn = () => {
    navigate(`/auth/sign_in`);
  };

  const changeTeam = () => {
    const queryParams = location.search;
    if (userStored.groups.includes("ClubAdmins")) {
      navigate(`/clubs/${userStored.club.id}/teams${queryParams}`, { state: { prevUrl: window.location.pathname + queryParams } });
    } else if (userStored.groups.includes("Players") || userStored.groups.includes("Coaches")) {
      navigate(`/users/${userStored.info.id}/teams${queryParams}`, { state: { prevUrl: window.location.pathname + queryParams } });
    }
  };

  let SignInBtn;

  if(1 === 0 && props.btnSignIn !== false) {
    SignInBtn = 
    <div>
      <CButton
        label={t('button.sign_in')}
        minWidth={90}
        type="contained"
        onClick={() => signIn()}
      />
    </div>
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item xs={2} sm={4}>
            <div className={classes.navBarRightItems}>
              <img src={appLogo} className={classes.logo} />
              <Box component={Grid} item display={{ xs: "none", md: "block" }}>
                <CText
                  fontSize={18}
                  fontWeight={700}
                >
                  Football Connexion
                </CText>
              </Box>
            </div>
          </Grid>
          {isLoading ?
            null
            :
            <Grid container item xs={10} sm={8} justifyContent="flex-end">
              {isAuthenticated === true ?
                <div className={classes.navBarRightItems}>
                  {team !== undefined && Object.keys(team).length !== 0 ?
                    <Tooltip title={`${JSON.parse(team.club.names[0]).name} • ${team.name}`}>
                      <span
                        className={classes.team}
                        onClick={(e) => changeTeam()}
                      >
                        <CText
                          className={classes.label}
                          level="p2"
                          fontWeight={700}
                          color="light"
                        >
                          <span className={classes.teamLogoZone}>
                            <ClubPhoto
                              className={classes.teamLogo}
                              src={team?.club?.logo}
                            />
                          </span>
                          {team?.name?.length > 7 ? team.name.slice(0, 5) + '...' : team.name}
                        </CText>
                      </span>
                    </Tooltip>
                    : null
                  }
                  { showMenuBtn === true ?
                    <NavItem icon={<MenuIcon />} navItemOpen={dropdownTopMenuOpen} open={dropdownTopMenuOpen} dropdownOpenCallback={dropdownTopMenuOpenCB} >
                      <DropdownTopMenu dropdownOpenCallback={dropdownTopMenuOpenCB} />
                    </NavItem>
                    : null
                  }
                  { props.btnCaret === false ?
                    null
                    :
                    <NavItem icon={<CaretIcon />} navItemOpen={dropdownSettingsOpen} open={dropdownSettingsOpen} dropdownOpenCallback={dropdownSettingsOpenCB}>
                      <DropdownSettings dropdownOpenCallback={dropdownSettingsOpenCB} />
                    </NavItem>
                  }
                </div>
                :
                SignInBtn
              }
            </Grid>
          }
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@mui/material/CircularProgress';

const COLOR_PRIMARY = '#45d69f';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;
const LABEL_COLOR = '#101116';

const ContainedButton = styled(Button)(({ maxWidth, minWidth, height }) => ({
  height: height || 'auto',
  backgroundColor: COLOR_PRIMARY,
  textTransform: 'none',
  borderRadius: 8,
  color: LABEL_COLOR,
  fill: LABEL_COLOR,
  fontSize: 15,
  boxShadow: 'none',
  fontWeight: '600',
  padding: '0px 12px',
  lineHeight: 2,
  maxWidth: maxWidth,
  minWidth: minWidth,
  fontFamily: [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  "&&:hover": {
    backgroundColor: COLOR_PRIMARY,
    filter: 'brightness(90%)',
    boxShadow: 'none'
  },
  '&:focus': {}
}));

const CButton = ({
  label,
  onClick,
  maxWidth,
  minWidth,
  height,
  color,
  type,
  size,
  className,
  children,
  loader,
  disabled
}) => {
  const useStyles = makeStyles((theme) => ({
    labelBtn: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      cursor: 'pointer',
      color: theme.palette.text.primary,
      "&:hover": {
        opacity: '0.8'
      }
    },
    button: {
      '&.Mui-disabled': {
        backgroundColor: color || COLOR_PRIMARY,
      },
      opacity: disabled ? 0.7 : 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      minWidth: minWidth,
      height: height,
      maxWidth: maxWidth,
      backgroundColor: color || COLOR_PRIMARY,
      transition: 'background-color 0.3s ease',
    },
    buttonContent: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      paddingLeft: 12,
      paddingRight: 12
    },
    loader: {
      visibility: props => props.isLoading ? 'visible' : 'hidden',
      position: 'absolute',
      color: `${theme.palette.text.primary} !important`
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      visibility: props => props.isLoading ? 'hidden' : 'visible',
    },
    labelWithPadding: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }));

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (onClick) {
      if (loader) setIsLoading(true);
      try {
        await onClick();
      } catch (error) {
        console.error("Error during async onClick:", error);
      }
      setIsLoading(false);
    }
  };

  const classes = useStyles({ isLoading, minWidth, maxWidth, height });

  let ButtonElement;
  if (type === "contained") {
    ButtonElement = (
      <ContainedButton
        style={{
          backgroundColor: color,
          fontSize: size,
          height: height,
          padding: 0,
        }}
        className={[classes.button, className].join(" ")}
        disabled={isLoading || disabled}
        variant="contained"
        onClick={handleClick}
      >
        <div className={classes.buttonContent}>
          <CircularProgress size={24} className={classes.loader} />
          <span className={`${classes.label}`}>{label}</span>
        </div>
      </ContainedButton>
    );
  } else if (type === "text") {
    ButtonElement = (
      <p
        style={{
          color: color,
          fontSize: size
        }}
        className={[classes.labelBtn, className].join(" ")}
        disabled={isLoading || disabled}
        onClick={handleClick}
      >
        {isLoading ? <CircularProgress size={24} /> : label}
      </p>
    );
  }

  return ButtonElement;
};

export const PickMediaBtn = ({label, onClick, maxWidth, minWidth, height, color, type, size, className, children, fileCb}) => {
  const useStyles = makeStyles((theme) => ({
    labelBtn: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      cursor: 'pointer',
      color: COLOR_PRIMARY,
      "&:hover": {
        opacity: '0.8'
      }
    }
  }));

  const classes = useStyles();

  const getFile = (e) => {
    fileCb(e.target.files[0]);
  };

  return(
    <ContainedButton
      style={{
        maxWidth: maxWidth,
        minWidth: minWidth,
        height: height,
        backgroundColor: color,
        fontSize: size,
        padding: '0px 0px',
      }}
      className={[className].join(" ")}
      disabled={false}
      type="submit"
      variant="contained"
      onClick={onClick}
    >
      <label htmlFor="formId" style={{paddingRight: 12, paddingLeft: 12, width: '100%', cursor: 'pointer' }} >
        {label}
        <input name="" type="file" id="formId" hidden style={{width: '100%', cursor: 'pointer'}} onChange={(e) => getFile(e)}  />
      </label>
    </ContainedButton>
  );
}

export default CButton;
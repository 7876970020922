import { makeStyles } from "@material-ui/core/styles";
import homeImg from '../../assets/images/home-photo.jpg';

const TOP_BAR_HEIGHT = 56;
const LIGHT_COLOR = "#f4f4f4";
const COLOR_PURPLE = "#9d44fd";
const COLOR_DARK = "#242834";
const COLOR_PRIMARY = '#45d69f';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: `calc(-30px - ${TOP_BAR_HEIGHT}px)`,
    marginBottom: -15,
    overflow: 'hidden'
  },
  content: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3%',
      paddingRight: '3%',
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px + 31px)`
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
      paddingRight: 30,
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px + 23px)`
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
    paddingTop: 30,
    paddingBottom: 30,
    display: "flex",
    flexDirection: 'row',
    alignItems: "center"
  },
  backgroundImage: {
    [theme.breakpoints.up('xs')]: {
      minHeight: `calc(100vh - 26px)`
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px + 10px)`
    },
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - ${TOP_BAR_HEIGHT + 31}px)`
    },
    position: 'relative',
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    justifyContent: "center",
    overflow: 'hidden',
    padding: "5%",
    width: 'calc(100% - 2*5%)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${homeImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      filter: 'brightness(55%)',
      zIndex: -1
    },
  },
  homeContent: {
    [theme.breakpoints.up('xs')]: {
       maxWidth: '80%'
    },
    [theme.breakpoints.up('sm')]: {
       maxWidth: '75%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '65%'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '55%'
    }
  },
  homeTitle: {
    [theme.breakpoints.up('xs')]: {
      fontSize: 22,
      marginBottom: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
      marginBottom: 30
    },
    fontWeight: '900'
  },
  content1: {
    backgroundColor: COLOR_DARK
  },
  content2: {
    backgroundColor: LIGHT_COLOR
  },
  title: {
    [theme.breakpoints.up('xs')]: {
      fontSize: 22,
      marginBottom: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 32,
      marginBottom: 30
    },
    fontWeight: '900'
  },
  title1: {
    color: COLOR_PRIMARY
  },
  title2: {
    color: COLOR_PURPLE
  },
  text: {
    [theme.breakpoints.up('xs')]: {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      marginBottom: 30
    },
    fontWeight: '600'
  },
  text1: {
    color: LIGHT_COLOR
  },
  text2: {
    color: COLOR_DARK
  },
  image: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 400
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: 500
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 550
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 600
    }
  },

  installBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  installBtn: {
    border: `2px solid ${COLOR_PRIMARY}`,
    borderRadius: 10,
    zIndex: 1000,
    padding: 8
  },
  timelineSection: {
    padding: '50px 10%',
    backgroundColor: LIGHT_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeline: {
    position: 'relative',
    margin: '20px 0'
  },
  step: {
    position: 'relative',
    paddingLeft: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  stepCircle: {
    position: 'relative',
    width: 22,
    height: 22,
    backgroundColor: COLOR_PRIMARY,
    borderRadius: '50%',
    display: 'inline-block',
    zIndex: 1,
  },
  stepLine: {
    width: 4,
    borderRadius: 2,
    height: 40,
    backgroundColor: COLOR_PRIMARY,
    zIndex: 0,
    marginBottom: 8,
    marginTop: 8
  },
  stepText: {
    fontSize: 18,
    color: COLOR_DARK,
    fontWeight: 'bold',
    marginLeft: 30
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  stepMenuIcon: {
    width: 20,
    height: 20
  },
  counterLabel: {
    display: 'inline !important',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontWeight: '700',
    textAlign: 'center',
    maxWidth: 550,
    lineHeight: '1.6',
    [theme.breakpoints.up('xs')]: {
      fontSize: 22,
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 26,
      marginBottom: 30
    },
  },
  counterLabelSpan: {
    display: 'inline',
    color: COLOR_PRIMARY
  },
  counterAppLogo: {
    marginRight: 10,
    [theme.breakpoints.up('xs')]: {
      width: 55,
      height: 55
    },
    [theme.breakpoints.up('md')]: {
      width: 70,
      height: 70
    }
  },
  counterAppName: {
    fontWeight: '700',
    color: LIGHT_COLOR,
    [theme.breakpoints.up('xs')]: {
      fontSize: 24
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 28
    }
  }
}));
import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

export const useStylesProfile = makeStyles((theme) => ({
  container: {
    height: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      marginRight: 0,
      marginLeft: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
      marginLeft: 0
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8%',
      paddingRight: '8%'
    }
  },
  userInfo: {
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30
  },
  seasonSummary: {
    display: "flex",
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 60
  },
  seasonSummaryTitle: {
    marginBottom: 15
  },
  infoTitle: {
    marginBottom: 30
  },
  seasonSummaryDataZone: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 450
  },
  seasonSummaryData:{
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  separator: {
    width: 2,
    borderRadius: 10,
    height: 22,
    backgroundColor: COLOR_LIGHT,
    marginLeft: 15,
    marginRight: 15
  },
  profilePicture: {
    border: '3px solid',
    marginRight: 20,
    borderRadius: '50%',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 120,
      height: 120
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: 150,
      height: 150
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 180,
      height: 180
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 200,
      height: 200
    },
    cursor: 'pointer',
    "&:hover": {
      filter: 'brightness(95%)'
    }
  },
  name: {
    marginBottom: 10
  },
  subTitle:{
    marginBottom: 15
  },
  team:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  teamLogo: {
    height: '100%',
    width: 'auto',
    maxHeight: 30,
    maxWidth: 30,
    marginLeft: 5,
    marginRight: 8
  },
  position: {
    marginRight: 15
  },
  table: {
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 12,
    paddingBottom: 25
  },
  tableIcon: {
    width: 20,
    height: 20,
    fill: COLOR_LIGHT
  },
  cardIcon: {
    width: 18,
    height: 18
  },
  yellowCard: {
    fill: `${COLOR_YELLOW} !important`
  },
  redCard: {
    fill: `${COLOR_RED} !important`
  },
  statsCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 15,
    listStyleType: 'none',
    boxSizing: 'border-box',
    padding: 20,
    width: 100,
    minHeight: 120,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 10,
    textAlign: 'center',
},
  statsCardValue: {
    margin: 'auto'
  },
  statsCardTitle: {
    marginTop: 'auto',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30
  },
  statsCardTitleIcon: {
    height: 25,
    width: 25,
    fill: COLOR_LIGHT
  }
}));

export const useStylesProfilePhotoModal = makeStyles((theme) => ({
  container: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    marginBottom: 30
  },
  selectPhoto: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  btnZone: {
    marginTop: 'auto',
    alignItems: 'baseline',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  btn:{
    maxWidth: 200
  },
  profilePicture: {
    border: '3px solid',
    marginBottom: 30,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      width: 150,
      height: 150
    },
    [theme.breakpoints.up('sm')]: {
      width: 180,
      height: 180
    },
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 200
    },
    [theme.breakpoints.up('lg')]: {
      width: 220,
      height: 220
    }
  },
  inlineBtn: {
    width: '100%'
  }
}));
import { ReactComponent as AddUserIcon } from '../../assets/icons/add-user.svg';
import { ReactComponent as TeamFlagIcon } from '../../assets/icons/team-flag.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as JerseyIcon } from '../../assets/icons/jersey.svg';
import { ReactComponent as PitchIcon } from '../../assets/icons/pitch.svg';
import { ReactComponent as PlayersIcon } from '../../assets/icons/players.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as StatisticsIcon } from '../../assets/icons/statistics.svg';
import { ReactComponent as DocumentsIcon } from '../../assets/icons/documents.svg';
import { ReactComponent as TacticsIcon } from '../../assets/icons/tactic.svg';
import { ReactComponent as EvaluationIcon } from '../../assets/icons/evaluation.svg';
import { ReactComponent as PreGameIcon } from '../../assets/icons/pre-game.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message.svg';

export const menuList = async(t, user) => {
  let menu;

  const coachMenu = [
    {
      id: "profile",
      title: t('menu.0.label'),
      icon: <ProfileIcon />,
      submenu: [],
      open: false,
      link_to: `/coaches/${user.info.id}`
    },
    {
      id: "invitations",
      title: t('menu.5.label'),
      icon: <AddUserIcon />,
      submenu: [],
      open: false,
      link_to: `/invitations`
    },
    {
      id: "team",
      title: t('menu.1.label'),
      icon: <JerseyIcon />,
      submenu: [
        {
          id: "team_players",
          icon: <PlayersIcon/>,
          title: t('menu.1.submenu.1.label'),
          link_to: `/teams/:teamName/players`
        },
        {
          id: "team_stats",
          icon: <StatisticsIcon/>,
          title: t('menu.1.submenu.2.label'),
          link_to: `/teams/:teamName/stats`
        },
        {
          id: "team_tactics",
          icon: <TacticsIcon/>,
          title: t('menu.1.submenu.3.label'),
          link_to: `/teams/:teamName/tactics`
        },
        {
          id: "team_documents",
          icon: <DocumentsIcon/>,
          title: t('menu.1.submenu.4.label'),
          link_to: `/teams/:teamName/documents`
        }
      ],
      open: true
    },
    {
      id: "messages",
      title: t('menu.6.label'),
      icon: <MessageIcon />,
      submenu: [],
      open: false,
      link_to: `/teams/:teamName/messages`
    },
    {
      id: "calendar_and_results",
      title: t('menu.2.label'),
      icon: <CalendarIcon/>,
      submenu: [],
      open: false,
      link_to: `/calendar_results`
    },
    {
      id: "matches",
      title: t('menu.3.label'),
      icon: <PitchIcon/>,
      submenu: [
        {
          id: "matches_pre_game",
          icon: <PreGameIcon />,
          title: t('menu.3.submenu.0.label'),
          link_to: `/matches/pre_game`
        },
        {
          id: "matches_after_game",
          icon: <EvaluationIcon/>,
          title: t('menu.3.submenu.1.label'),
          link_to: `/matches/after_game`
        }
      ],
      open: true
    }
  ];

  const playerMenu = [
    {
      id: "profile",
      title: t('menu.0.label'),
      icon: <ProfileIcon />,
      submenu: [],
      open: false,
      link_to: `/players/${user.info.id}`
    },
    {
      id: "invitations",
      title: t('menu.5.label'),
      icon: <AddUserIcon />,
      submenu: [],
      open: false,
      link_to: `/invitations`
    },
    {
      id: "team",
      title: t('menu.1.label'),
      icon: <JerseyIcon />,
      submenu: [
        {
          id: "team_players",
          icon: <PlayersIcon/>,
          title: t('menu.1.submenu.1.label'),
          link_to: `/teams/:teamName/players`
        },
        {
          id: "team_tactics",
          icon: <TacticsIcon/>,
          title: t('menu.1.submenu.3.label'),
          link_to: `/teams/:teamName/tactics`
        }
      ],
      open: true
    },
    {
      id: "messages",
      title: t('menu.6.label'),
      icon: <MessageIcon />,
      submenu: [],
      open: false,
      link_to: `/teams/:teamName/messages`
    },
    {
      id: "calendar_and_results",
      title: t('menu.2.label'),
      icon: <CalendarIcon/>,
      submenu: [],
      open: false,
      link_to: `/calendar_results`
    },
    {
      id: "matches",
      title: t('menu.3.label'),
      icon: <PitchIcon/>,
      submenu: [
        {
          id: "matches_pre_game",
          icon: <PreGameIcon />,
          title: t('menu.3.submenu.0.label'),
          link_to: `/matches/pre_game`
        },
        {
          id: "matches_after_game",
          icon: <EvaluationIcon/>,
          title: t('menu.3.submenu.1.label'),
          link_to: `/matches/after_game/players/:teamName`
        }
      ],
      open: true
    }
  ];

  const clubAdminMenu = [
    {
      id: "home",
      title: t('menu.4.label'),
      icon: <TeamFlagIcon />,
      submenu: [],
      open: false,
      link_to: `/clubs/${user.club.id}`
    },
    {
      id: "team",
      title: t('menu.1.label'),
      icon: <JerseyIcon />,
      submenu: [
        {
          id: "team_players",
          icon: <JerseyIcon />,
          title: t('menu.1.submenu.0.label'),
          link_to: `/teams`
        },
        {
          id: "team_players",
          icon: <PlayersIcon />,
          title: t('menu.1.submenu.1.label'),
          link_to: `/teams/:teamName/players`
        },
        {
          id: "team_stats",
          icon: <StatisticsIcon />,
          title: t('menu.1.submenu.2.label'),
          link_to: `/teams/:teamName/stats`
        },
        {
          id: "team_tactics",
          icon: <TacticsIcon/>,
          title: t('menu.1.submenu.3.label'),
          link_to: `/teams/:teamName/tactics`
        },
        {
          id: "team_documents",
          icon: <DocumentsIcon/>,
          title: t('menu.1.submenu.4.label'),
          link_to: `/teams/:teamName/documents`
        }
      ],
      open: true
    },
    {
      id: "calendar_and_results",
      title: t('menu.2.label'),
      icon: <CalendarIcon />,
      submenu: [],
      open: false,
      link_to: `/calendar_results`
    }
  ];

  const groups = user.groups
  if(groups.includes("Coaches")) {
    menu = coachMenu
  } else if (groups.includes("Players")) {
    menu = playerMenu
  } else if (groups.includes("ClubAdmins")) {
    menu = clubAdminMenu
  }

  return menu;
}
import React, { useState, useEffect, useRef } from 'react';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots-vertical.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import ProfilePicture from "../ProfilePicture/index";
import { useNavigate } from "react-router-dom";
import Checkbox from "../Checkbox/Checkbox.js";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { API, graphqlOperation } from 'aws-amplify';
import { downloadFile, deleteUserClubDocuments, uploadUserClubDocuments } from '../../utils/file.js';
import { SnackBarError } from "../../components/SnackBar";

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

const COLOR_PRIMARY = '#45d69f'
const COLOR_LIGHT = "#f4f4f4"
const COLOR_PINK= "#e92163"
const COLOR_BLUE = "#25a5e6"
const COLOR_DARK = '#242834'
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const ZONE_BACKGROUND_COLOR = "#1f2126"
const ROW_COLOR_1 = '#24262a'
const ROW_COLOR_2 = '#1d2027'
const BUTTON_WIDTH = 100

const useStyles = makeStyles((theme) => ({
  cardIcon: {
    width: 18,
    height: 18
  },
  icon: {
    width: 20,
    height: 20,
    fill: COLOR_LIGHT
  },
  iconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20+6,
    height: 20+6,
    cursor: 'pointer',
    borderRadius: 15,
    "&:hover": {
      backgroundColor: '#232e2f',
      filter: 'brightness(90%)',
      boxShadow: 'none'
    }
  },
  playerPhoto: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: '1px solid',
    marginRight: 10
  },
  headCell: {
    fontSize: '14px !important',
    fontWeight: '700 !important'
  },
  cell: {
    color: `${COLOR_LIGHT} !important`,
    borderBottom: 'none !important',
    minWidth: '80px !important'
  },
  cellContent: {
    display:'flex',
    justifyContent: 'center'
  },
  cellContentWithSort: {
    marginLeft: -22
  },
  stickyCell: {
    position: 'sticky !important',
    left: '0px !important',
    width: '180px !important',  
    zIndex: `1 !important`,
    marginTop: 1,
    display: 'flex !important',
    flexDirection: 'row !important',
    alignItems: 'center !important'
  },
  stickyCellColor1: {
    borderRadius: `16px !important`,
    backgroundColor: `${ZONE_BACKGROUND_COLOR} !important`
  },
  stickyCellColor2: {
    backgroundColor: `${ROW_COLOR_1} !important`
  },
  stickyCellColor3: {
    backgroundColor: `${ROW_COLOR_2} !important`
  },
  listIcon: {
    width: 17,
    height: 17,
    fill: COLOR_DARK
  },
  listIconZone: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center'
  }
}))

const createData = (id, player, photo_path, position, documents_id, license_path, contribution, id_card_path, club_rules_path, health_questionnaire_path, medical_certificate_path, image_rights_form_path) => {
  return {
    id,
    player,
    photo_path,
    position,
    documents_id,
    license_path,
    contribution,
    id_card_path,
    club_rules_path,
    health_questionnaire_path,
    medical_certificate_path,
    image_rights_form_path
  }
}

const  descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const  stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const headCells = [
    {
      id: "player",
      align: 'left',
      numeric: false,
      disablePadding: false,
      label: props.translation('team_documents.arrays.0.headers.0'),
      icon: null
    },
    {
      id: "position",
      align: 'center',
      numeric: false,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.1'),
      icon: null
    },
    {
      id: "document_1",
      align: 'center',
      numeric: true,
      disablePadding: false,
      label: props.translation('team_documents.arrays.0.headers.2'),
      icon: null
    },
    {
      id: "document_2",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.3'),
      icon: null
    },
    {
      id: "document_3",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.4'),
      icon: null
    },
    {
      id: "document_4",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.5'),
      icon: null
    },
    {
      id: "document_5",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.6'),
      icon: null
    },
    {
      id: "document_6",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.7'),
      icon: null
    },
    {
      id: "document_7",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.8'),
      icon: null
    }
  ]

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            className={`${classes.cell} ${classes.headCell}  ${index === 0 ? `${classes.stickyCell} ${classes.stickyCellColor1}` : ''}`}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {index < 2 ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  cursor: 'pointer',
                  '&.Mui-selected': {
                    '& > *': {
                      color: COLOR_LIGHT,
                    },
                  },
                  '&.Mui-active': {
                    color: COLOR_LIGHT,
                    '& .MuiSvgIcon-root:not(.Mui-active)': {
                      fill: COLOR_LIGHT
                    }
                  },
                  '&:hover': {
                    color: COLOR_LIGHT,
                    filter: 'brightness(90%)',
                    '& > *': {
                      filter: 'brightness(90%)'
                    }
                  }
                }}
              >
                {headCell.icon ?
                  <Tooltip title={`${headCell.label}`}>
                    {headCell.icon}
                  </Tooltip>
                  : headCell.label
                }
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              :
              <div>
                {headCell.icon ?
                  <Tooltip title={`${headCell.label}`}>
                    {headCell.icon}
                  </Tooltip>
                  : headCell.label
                }
              </div>
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const DocumentCell = ({ fileType, label, path, index, row, t, userStored, onChange }) => {
  const classes = useStyles();
  const uploadInputRef = useRef(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("false");
  

  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const addDocument = () => {
    uploadInputRef.current.click();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const deleteDocument = async(user, index, fileType) => {
    const userFilesUpdated = await deleteUserClubDocuments(user, fileType);
    onChange(index, userFilesUpdated);
    handleClose();
  };

  const handleFileSelection = async (event, index, user, fileType) => {
    try {
      const userFilesUpdated = await uploadUserClubDocuments(event, userStored?.club?.id, user, fileType);
      onChange(index, userFilesUpdated);
    } catch (error) {
      if(error.code === 1) {
        setSnackBarErrorMessage(t('team_documents.errors.0.message'));
        setOpenSnack(true);
      }
    }
  };

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setSnackBarErrorMessage("")
  };

  return (
    <div className={`${classes.cellContent}`}>
      <input
        id="uploadInput"
        ref={uploadInputRef}
        name=""
        type="file"
        hidden
        style={{ width: '100%', cursor: 'pointer' }}
        onChange={(e) => handleFileSelection(e, index, row, fileType)}
      />
      {path ? (
        <div className={`${classes.iconBtn}`}>
          <Tooltip title={t('team_documents.contents.0.labels.0')}>
            <DownloadIcon
              className={`${classes.icon}`}
              onClick={() => downloadFile(path, row.player, label)}
            />
          </Tooltip>
        </div>
      ) : null}
      <div className={`${classes.iconBtn}`}>
        <Tooltip title={t('team_documents.contents.0.labels.3')}>
          <DotsIcon
            className={`${classes.icon}`}
            onClick={handleClickMore}
          />
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              minWidth: '10ch'
            },
          }}
          autoFocus={false}
        >
          {path ? (
            <MenuItem onClick={() => deleteDocument(row, index, fileType)}>
              <span className={classes.listIconZone}>
                <TrashIcon className={classes.listIcon} />
              </span>
              {t('team_documents.contents.0.labels.2')}
            </MenuItem>
          ) : (
            <MenuItem onClick={addDocument}>
              <span className={classes.listIconZone}>
                <UploadIcon className={classes.listIcon} />
              </span>
              {t('team_documents.contents.0.labels.1')}
            </MenuItem>
          )}
        </Menu>
      </div>
      <SnackBarError
        open={openSnack}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
};

const PlayersDocumentsTable = ({ data, clubId, season }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let navigate = useNavigate();
  const userStored = useSelector(state => state?.user);

  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      const newRows = data.map((item) => {
        const fullName = `${item.first_name} ${item.last_name}`;
        return createData(
          item.id,
          fullName,
          item.photo_path,
          item.position,
          item.documents.id,
          item.documents.license_path,
          { id: item.contribution.id, paid: item.contribution.paid },
          item.documents.id_card_path,
          item.documents.club_rules_path,
          item.documents.health_questionnaire_path,
          item.documents.medical_certificate_path,
          item.documents.image_rights_form_path
        );
      });
      setRows(newRows);
    }
  }, [data]);

  const visibleRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [rows, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.player)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const onChangeContribution = async(value, data) => {
    try {
      const updateData = {
        id: data.contribution.id,
        paid: value
      }

      await API.graphql({
        query: mutations.updateUserClubContribution,
        variables: {input: updateData},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
  
      const newContributionPaid = value
  
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === data.id
            ? {
                ...row,
                contribution: {
                  ...row.contribution,
                  id: row.contribution.id,
                  paid: newContributionPaid
                },
              }
            : row
        )
      );
    } catch (err) {
      console.log('error fetching:', err);
    }
  }

  const onChangeDocuments = (index, updatedRow) => {
    setRows((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, ...updatedRow } : row
      )
    );
  };
    
  return (
    <TableContainer>
      <Table
        sx={{}}
        aria-labelledby="tableTitle"
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          translation={t}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `table-${index}`
            const rowColor = index % 2 === 0 ? ROW_COLOR_1 : ROW_COLOR_2;

            return (
              <TableRow
                tabIndex={-1}
                key={row.id}
                sx={{
                  backgroundColor: rowColor,
                  borderBottom: 'none',
                  color: COLOR_LIGHT
                }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  className={`
                    ${classes.cell}
                    ${classes.stickyCell}
                    ${classes.stickyCellColor1}
                    ${index%2 === 0 ? `${classes.stickyCellColor2}` : `${classes.stickyCellColor3}`}
                  `}
                >
                  <div>
                    <ProfilePicture className={classes.playerPhoto} src={row.photo_path} />
                  </div>
                  {row.player}
                </TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                  <span className={`${classes.cellContent} ${classes.cellContentWithSort}`}>
                    {row.position}
                  </span>
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="license"
                    label={t(`team_documents.arrays.0.headers.2`)}
                    path={row.license_path}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <div className={`${classes.cellContent}`}>
                    <Checkbox
                      onChange={(value) => onChangeContribution(value, row)}
                      defaultValue={row.contribution.paid}
                    />
                  </div>
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="id_card"
                    label={t(`team_documents.arrays.0.headers.4`)}
                    path={row.id_card_path}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="medical_certificate"
                    label={t(`team_documents.arrays.0.headers.5`)}
                    path={row.medical_certificate_path}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="health_questionnaire"
                    label={t(`team_documents.arrays.0.headers.6`)}
                    path={row.health_questionnaire_path}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="club_rules"
                    label={t(`team_documents.arrays.0.headers.7`)}
                    path={row.club_rules_path}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="image_rights"
                    label={t(`team_documents.arrays.0.headers.8`)}
                    path={row.image_rights_form_path}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PlayersDocumentsTable;
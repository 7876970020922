import React, { useEffect, useState, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';

import { store } from './store/index';
import App from './screens/App';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const getLanguage = () => {
  const selectedLanguageFromLocalStorage = localStorage.getItem("selectedLanguage");
  const browserLanguage = navigator.language || navigator.userLanguage;
  const language = selectedLanguageFromLocalStorage || browserLanguage;

  return ["en", "fr"].includes(language.substr(0, 2)) ? language.substr(0, 2) : "en";
};

const initLanguage = () => {
  const selectedLanguage = getLanguage();
  if (selectedLanguage) {
    i18n.changeLanguage(selectedLanguage);
  }
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr"],
    lng: getLanguage(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    }
  });

initLanguage();

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

serviceWorker.register();
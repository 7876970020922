import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getClubId, getSession } from "../../utils/auth"
import { getCurrentSeason } from "../../utils/season"

import * as queries from '../../graphql/queries'
import { Auth, API } from 'aws-amplify'

export const signIn = createAsyncThunk('user/signIn', async (_, thunkAPI) => {
  const session = await getSession();
  const groups = session.idToken.payload['cognito:groups'];
  const userId = session.idToken.payload.sub;
  let [clubId, listInvitations, clubNames] = ["", null, []];
  let teams = [];
  let res = {};

  if (groups.includes("Coaches") || groups.includes("Players")) {
    const currentSeason = await getCurrentSeason();
    const filter = {
      season: { eq: currentSeason },
      user_id: { eq: userId },
      invite_status: { eq: 1 }
    };

    // Obtenir les clubs de l'utilisateur pour la saison actuelle
    teams = await API.graphql({ query: queries.listInvitations, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    teams = teams.data.listInvitations.items;
    clubId = teams[0]?.team?.club?.id;

    if (teams) {
      teams[0]?.team?.club?.names.forEach(name => {
        clubNames.push(JSON.parse(name));
      });
    }

    res.teamId = teams ? teams[0]?.team?.id : null;
    res.teamName = teams ? teams[0]?.team?.name : null;
  } else if (groups.includes("ClubAdmins")) {
    clubId = await getClubId();

    const teamsFilter = {
      club_id: { eq: clubId },
      deleted: { eq: false }
    };

    let teamsList = await API.graphql({
      query: queries.listClubTeams,
      variables: { filter: teamsFilter },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    teamsList = teamsList.data.listTeams.items;

    const teamsListOrdered = teamsList.sort((a, b) => {
      const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
      const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
      return numberA - numberB;
    });

    teams = teamsListOrdered;
    res.teamId = teams ? teams[0]?.id : null;
    res.teamName = teams ? teams[0]?.name : null;
  }

  res.userId = userId;
  res.groups = groups;
  res.clubId = clubId;
  res.clubNames = clubNames;
  res.clubLogoPath = teams ? teams[0]?.team?.club?.logo : null;

  return res;
});

export const addTeam = createAsyncThunk('user/addTeam', async (data, { getState }) => {
  const state = getState();
  const { user } = state;

  // Préparation des données de retour
  const result = {
    clubId: data.clubId,
    teamId: data.teamId
  };

  // Mise à jour du localStorage
  const persistRoot = localStorage.getItem('persist:root');
  if (persistRoot) {
    // Parser le JSON du localStorage
    const parsedPersistRoot = JSON.parse(persistRoot);
    
    // Mettre à jour uniquement la partie du user dans le localStorage
    if (parsedPersistRoot.user) {
      const user = JSON.parse(parsedPersistRoot.user);

      // Assurer que la partie `club` et `team` est bien mise à jour
      user.club = {
        ...user.club,
        id: result.clubId,
        team: {
          ...user.club.team,
          id: result.teamId
        }
      };

      // Mettre à jour le localStorage avec les nouvelles données
      localStorage.setItem('persist:root', JSON.stringify({
        ...parsedPersistRoot,
        user: JSON.stringify(user)
      }));
    }
  }

  return result;
});


export const getTeam = createAsyncThunk('user/getTeam', async () => {
  // Récupérer la valeur du localStorage
  const persistRoot = localStorage.getItem('persist:root');
  if (persistRoot) {
    // Parser la valeur récupérée
    const parsedPersistRoot = JSON.parse(persistRoot);
    if (parsedPersistRoot.user) {
      // Récupérer l'objet utilisateur
      const user = JSON.parse(parsedPersistRoot.user);

      // Vérifier si l'utilisateur a une équipe
      if (user.club && user.club.team) {
        return {
          teamId: user.club.team.id || null,
          teamName: user.club.team.name || null,
          clubId: user.info.club_id || null,
          clubNames: user.club.names || [],
          clubLogoPath: user.club.logo_path || null
        };
      }
    }
  }

  return {
    teamId: null,
    teamName: null,
    clubId: null,
    clubNames: [],
    clubLogoPath: null
  };
});

export const updateTeam = createAsyncThunk('user/updateTeamId', async (data) => {
  return {
    id: data.id,
    name: data.name,
    club: { id: data.club.id, name: data.club.name, }
  };
});

export const setPhotoPath = createAsyncThunk('user/setPhotoPath', async (data) => {
  return { photoPath: data.photoPath }
});

export const setClubLogoPath = createAsyncThunk('user/setClubLogoPath', async (data) => {
  return { photoPath: data.photoPath }
});

export const user = createSlice({
  name: 'user',
  initialState: {
    info: {
      id: "",
      club_id: ""
    },
    first_name: "",
    last_name: "",
    groups: [],
    club: {},
    isAuthenticated: false
  },
  reducers: {
    signOut: (state) => {
      state.isAuthenticated = false
      state.info = {}
      state.groups = []
      state.club = {}
      state.first_name = ""
      state.last_name = ""
      state.photo_path = ""
    }
  },
  extraReducers: {
    [signIn.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.info.id = payload.userId;
      state.groups = payload.groups;
      state.club = {
        id: payload.clubId,
        names: payload.clubNames,
        logo_path: payload.clubLogoPath,
        team: {
          id: payload.teamId,
          name: payload.teamName
        }
      };
    },
    [addTeam.fulfilled]: (state, { payload }) => {
      state.club = {
        "id": payload.clubId,
        "team": {
          "id": payload.teamId
        }
      }
    },
    [setPhotoPath.fulfilled]: (state, { payload }) => {
      // only update photo_path if it's not an empty string
      if (payload.photoPath !== "") {
        state.photo_path = payload.photoPath
      }
      return state
    },
    [updateTeam.fulfilled]: (state, { payload }) => {
      state.club.team.id = payload.id;
      state.club.team.name = payload.name;
      state.club.id = payload.club.id;
    },
    [getTeam.fulfilled]: (state, { payload }) => {
      state.club = {
        "id": payload.clubId,
        "team": {
          "id": payload.teamId
        }
      };
    }
  }
})

export const { signOut } = user.actions;
export const getIsAuthenticated = (state) => state?.user.isAuthenticated;
export default user.reducer;
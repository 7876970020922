import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { CText } from "../../components/Text";
import CButton from "../../components/Button";
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import { decrementInvitations, setInvitations } from '../../store/reducers/invitations';
import { addTeam, getTeam } from '../../store/reducers/user';
import { Auth } from 'aws-amplify';
import { useStylesInvitations } from './Invitations.styles.js';
import CircularProgress from '@mui/material/CircularProgress';

const Invitation = ({ invitation, index, invitations }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStylesInvitations();

  const [localInvitation, setLocalInvitation] = useState(invitation);
  const [loading, setLoading] = useState(false);

  // useEffect pour mettre à jour localInvitation lorsqu'il y a un changement dans la prop invitation
  useEffect(() => {
    setLocalInvitation(invitation);
  }, [invitation]);

  const handleInvitationBtnClick = useCallback(async (type) => {
    setLoading(true);

    const teamId = localInvitation.team.id;
    const clubId = localInvitation.team.club.id;
    const invite_status = type === "accept" ? 1 : 2;
    const input = { invite_status: invite_status };

    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/invitations/v1/invitations/${localInvitation.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: JSON.stringify(input)
      });

      if (!resp.ok) {
        throw new Error("Failed to update invitation status");
      }

      // Mettre à jour l'état local de l'invitation
      setLocalInvitation(prevInvitation => ({
        ...prevInvitation,
        invite_status: invite_status
      }));

      // Mettre à jour le store Redux
      const updatedInvitations = invitations.map(inv =>
        inv.id === localInvitation.id ? { ...localInvitation, invite_status: invite_status } : inv
      );
      dispatch(setInvitations(updatedInvitations));
      dispatch(decrementInvitations());

      // Vérifier si une équipe est présente et ajouter si nécessaire
      const team = await dispatch(getTeam()).unwrap();
      if (!team || !teamId) {
        dispatch(addTeam({
          clubId: clubId,
          teamId: teamId
        }));
      }
    } catch (error) {
      console.error("Error updating invitation status:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, localInvitation, invitations]);

  return (
    <Grid item xs={12} key={index}>
      <Grid container item xs={12} alignItems="center" className={classes.invitationZone}>
        <Grid container item xs={9} sm={10} direction="row" alignItems="center">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ClubPhoto
              className={classes.teamLogo}
              src={localInvitation.team.club.logo}
            />
            <CText className={classes.listTitle} level="p2" fontWeight={500} color="light">
              <span>
                <span style={{ fontWeight: "700" }}>{JSON.parse(localInvitation.team.club.names[0]).name}</span>
                <span> {localInvitation.type === "C" ? t('invitation.labels.0') : t('invitation.labels.1')}</span>
                <span style={{ fontWeight: "700" }}> {localInvitation.team.name}</span>
              </span>
            </CText>
          </div>
        </Grid>
        <Grid container item xs={3} sm={2} direction="row" justifyContent="flex-end">
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {localInvitation.invite_status === 0 && (
                <div>
                  <CButton
                    className={classes.acceptBtn}
                    label={t('invitation.buttons.0.label')}
                    type="contained"
                    color='#45d69f'
                    size={12}
                    height={24}
                    onClick={() => handleInvitationBtnClick("accept")}
                  />
                  <CButton
                    className={classes.denyBtn}
                    label={t('invitation.buttons.1.label')}
                    type="contained"
                    color='#e92163'
                    size={12}
                    height={24}
                    onClick={() => handleInvitationBtnClick("refuse")}
                  />
                </div>
              )}
              {localInvitation.invite_status === 1 && !loading && (
                <CText className={classes.listTitle} level="p3" color="primary" fontWeight={700} label={`${t('invitation.statuses.1.label')}`} />
              )}
              {localInvitation.invite_status === 2 && !loading && (
                <CText className={classes.listTitle} level="p3" color="red" fontWeight={700} label={`${t('invitation.statuses.2.label')}`} />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Invitation;
import React, { useState } from 'react'
import TextInput from '../../components/TextInput.js'
import CButton from "../../components/Button.js"
import { makeStyles } from "@material-ui/core/styles"
import { sessionHandler } from '../../utils/auth'
import { useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Auth } from 'aws-amplify'
import { CText } from "../../components/Text"
import { useDispatch } from 'react-redux'
import { signIn } from '../../store/reducers/user'
import { SnackBarError } from "../../components/SnackBar"
import { useStylesAuth } from './auth.styles.js'

const NewPassword = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const classes = useStylesAuth()

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  
  const [newPwd, setNewPwd] = useState(null);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const password = searchParams.get("password");

  // Click Buttons
  const submitClick = async() => {
    try {
      const user = await Auth.signIn(email, password);
      const userAuthenticated = await Auth.completeNewPassword(user, newPwd);
      console.log("Password changed.");
      console.log("User authenticated.");
      sessionHandler();
      dispatch(signIn());
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };



  // Infos Callbacks
  const newPwdCb = (data) =>{ setNewPwd(data) };

  return(
    <div className={classes.container}>
      <div className={classes.formZone}>
        <TextInput className={classes.input} label={t('common.new_password')} type="password" inputCallback={newPwdCb} />
        <CButton
          className={classes.formBtn}
          label={t('button.confirm')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await submitClick()}
        />
      </div>

      <SnackBarError
        open={openSnack}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
}

export default NewPassword;
import {makeStyles} from "@material-ui/core/styles"

export const useStylesAddModal = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20
    },
    [theme.breakpoints.up('md')]: {
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  formTitle: {
    marginBottom: 30,
    textAlign: 'center'
  },
  input: {
    width: '100%',
    marginBottom: 15
  },
  formBtn: {
    marginTop: 'auto',
    alignItems: 'baseline',
    width: 200
  }
}));

export const useStylesInvitations = makeStyles((theme) => ({
  acceptBtn: {
    marginBottom: 6,
    width: 90
  },
  denyBtn: {
    width: 90
  },
  container: {
    height: '100%',
    flex: 1
  },
  title: {
    marginBottom: 20
  },
  invitationZone: {
    backgroundColor: "#1f2126",
    borderRadius: 15,
    listStyleType: 'none',
    [theme.breakpoints.up('xs')]: {
      padding: 15
    },
    [theme.breakpoints.up('sm')]: {
      padding: 15
    },
    [theme.breakpoints.up('md')]: {
      padding: 15
    },
    [theme.breakpoints.up('lg')]: {
      padding: 20
    }
  },
  photo: {
    width: 40,
    height: 40,
    borderRadius: 100,
    border: '2px solid',
    marginRight: 10
  },
  teamLogo: {
    maxWidth: 30,
    marginRight: 10
  },
  noInvitations: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { getSession, getClubId } from "../../utils/auth";
import { useDispatch } from 'react-redux';
import { useStylesMenu, useStylesSideMenu } from './Menu.styles.js';
import { ReactComponent as RightChevronIcon } from '../../assets/icons/right-chevron.svg';
import { CText } from "../Text.js";
import { menuList } from "./list.js";
import { getRealTimeInvitations } from '../../utils/invitation';
import { messagesCounter, fetchConversation } from '../../utils/conversation';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';
import { getCurrentSeason } from "../../utils/season";

const COLOR_LIGHT = "#f4f4f4";
const MENU_BACKGROUND_COLOR = "rgba(28, 30, 33, 1)";
const MENU_LABEL_COLOR = "rgba(242, 242, 242, 1)";
const MENU_LABEL_ACTIVE_COLOR = "rgba(69, 214, 159, 1)";
const MENU_LABEL_ACTIVE_BACKGROUND_COLOR = "rgba(69, 214, 159, 0.07)";
const MENU_WIDTH = 250;
const MENU_LABEL_MARGIN = 10;
const MENU_LABEL_PADDING_HORIZONTAL = 15;
const MENU_LABEL_PADDING_VERTICAL = 5;
const COLOR_PRIMARY = "#45d69f";
const COLOR_PINK = "#e92163";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: 'transparent',
  '&:not(:last-child)': {
    border: 0,
  },
  '&:before': {
		border: 0,
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme, ...props }) => {
  return {
    minHeight: 0,
    borderRadius: 8,
    height: 35,
    paddingLeft: MENU_LABEL_PADDING_HORIZONTAL,
    paddingRight: MENU_LABEL_PADDING_HORIZONTAL,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: MENU_LABEL_PADDING_VERTICAL,
      "&$expanded": {
        padding: MENU_LABEL_PADDING_VERTICAL,
      },
    },
  };
});

const AccordionSummary2 = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme, ...props }) => {
  return {
    minHeight: 0,
    borderRadius: 8,
    height: 40,
    paddingLeft: MENU_LABEL_PADDING_HORIZONTAL,
    paddingRight: MENU_LABEL_PADDING_HORIZONTAL,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: MENU_LABEL_PADDING_VERTICAL,
      "&$expanded": {
        padding: MENU_LABEL_PADDING_VERTICAL,
      },
    },
  };
});


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
	paddingBottom: 0,
	border: 0,
	margin: 0,
	marginTop: -14
}));

export const SideMenu = () => {
  const classes = useStylesMenu();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const user = useSelector(state => state?.user);
	const userId = user?.info?.id;
	const teamId = user?.club?.team?.id;

	const [loading, setLoading] = useState(true);
	const [menu, setMenu] = useState([]);
	const [conversationId, setConversationId] = useState("");

  useEffect(() => {  
    let InvitationSubscription;
    let isMounted = true;

    const fetchData = async () => {
      loadMenu();

      const session = await getSession();
      const groups = session.idToken.payload['cognito:groups'];

      if (groups && (groups.includes("Players") || groups.includes("Coaches"))) {
        InvitationSubscription = await getRealTimeInvitations(userId, isMounted, dispatch);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (InvitationSubscription) {
        InvitationSubscription.unsubscribe();
      }
    };
  }, [userId]);

  const MessageSubscriptionRef = useRef(null);

	useEffect(() => {
    let isMounted = true;

    const subscribeToNewConversations = async (season) => {
        try {
            const subscription = await API.graphql(
        			graphqlOperation(subscriptions.onCreateConversation, { team_id: teamId, season: season })
            ).subscribe({
                next: async ({ value: { data } }) => {
									alert(season)
                    const newConversation = data.onCreateConversation;
                    if (newConversation && isMounted) {
                        setConversationId(newConversation.id);

                        if (MessageSubscriptionRef.current) {
                            MessageSubscriptionRef.current.unsubscribe();
                            MessageSubscriptionRef.current = null;
                        }

                        MessageSubscriptionRef.current = await messagesCounter(
                            userId,
                            teamId,
                            newConversation.id,
                            isMounted,
                            dispatch
                        );
                    }
                },
                error: error => console.error('Erreur de souscription à la création de conversation:', error),
            });

            return subscription;
        } catch (error) {
            console.error('Erreur lors de la souscription à onCreateConversation:', error);
        }
    };

    const fetchData = async () => {
        try {
            const session = await getSession();
            const groups = session.idToken.payload['cognito:groups'];

            if (groups && (groups.includes("Players") || groups.includes("Coaches"))) {
                const conversation = await fetchConversation(teamId);

                if (isMounted) {
                    if (conversation && conversation.id) {
                        setConversationId(conversation.id);

                        if (MessageSubscriptionRef.current) {
                            MessageSubscriptionRef.current.unsubscribe();
                            MessageSubscriptionRef.current = null;
                        }

                        MessageSubscriptionRef.current = await messagesCounter(
                            userId,
                            teamId,
                            conversation.id,
                            isMounted,
                            dispatch
                        );
                    } else {
											// Si aucune conversation n'est trouvée, souscrire à onCreateConversation
											const currentSeason = await getCurrentSeason();
											await subscribeToNewConversations(currentSeason);
                    }
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        }
    };

    fetchData();

    return () => {
        isMounted = false;

        if (MessageSubscriptionRef.current) {
            MessageSubscriptionRef.current.unsubscribe();
            MessageSubscriptionRef.current = null;
        }
    };
}, [teamId, userId, dispatch]);


	const invitationCount = useSelector((state) => state?.invitations?.count);
	const conversation = useSelector((state) =>
		state?.conversations?.conversations.find(convo => convo.id === conversationId)
	);
	const conversationCount = conversation ? conversation.count : 0;

	const loadMenu = async() => {
		let menu = await menuList(t, user);
		setMenu(menu)
		setLoading(false)
	}

	const handleChange = (menuIndex) => {
		let newMenu = [...menu]
  	newMenu[menuIndex].open = !menu[menuIndex].open
		setMenu(newMenu)
	};

	const handleNavigate = (path, item) => {
    const teamName = user?.club?.team?.name;
    const currentPath = path.replace(':teamName', encodeURIComponent(teamName));

    const url = new URL(currentPath, window.location.origin);

    if (item.id === "calendar_and_results") {
      url.searchParams.set('team', teamName);
    }

    // En séparant le chemin et les paramètres de recherche
    const finalPath = `${url.pathname}${url.search}`;
    navigate(finalPath);
    window.scrollTo(0, 0);
	};

	const isPathActive = (currentPath, menuPath) => {
    const segmentsMenu = menuPath?.split('/');
    const segmentsCurrent = currentPath?.split('/');
    
    if (segmentsMenu?.length !== segmentsCurrent?.length) return false;

    for (let i = 0; i < segmentsMenu?.length; i++) {
        if (segmentsMenu[i] === ':teamName') continue;
        if (segmentsMenu[i] !== segmentsCurrent[i]) return false;
    }

    return true;
	}

const renderSubMenu = (submenu) => {
	return submenu.map((subItem, submenuIndex) => {
			const isActiveSub = isPathActive(location.pathname, subItem.link_to);
			return (
					<div
							onClick={() => handleNavigate(subItem.link_to, subItem)}
							key={subItem.title}
							className={[
									classes.subTitleZone,
									isActiveSub ? classes.subTitleZoneFocus : ""
							].join(" ")}
					>
							<div className={isActiveSub ? classes.menuIconFocus : classes.menuIcon}>
									{subItem.icon}
							</div>
							<CText fontSize={15} fontWeight={500} label={subItem.title} />
					</div>
			);
	});
}

const renderMenu = (menuItem, menuIndex) => {
	const isActiveMenu = isPathActive(location.pathname, menuItem.link_to);
	const isActiveSubMenu = menuItem.submenu.some(sub => isPathActive(location.pathname, sub.link_to));

	if (menuItem.submenu.length < 1) {
			return (
					<div
						onClick={() => handleNavigate(menuItem.link_to, menuItem)}
						className={[
							classes.titleZone,
							isActiveMenu ? classes.titleZoneFocus : ""
						].join(" ")}
					>
						<div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
							{menuItem.icon}
						</div>
						<CText fontSize={15} fontWeight={500} style={{ position: 'relative' }}>
							{menuItem.title}
							{menuItem.id === "invitations" && invitationCount > 0 && (
								<span className={classes.badgeZone}>
									<span className={classes.badge}>
										{invitationCount}
									</span>
								</span>
							)}
							{menuItem.id === "messages" && conversationCount > 0 && (
								<span className={classes.badgeZone}>
									<span className={classes.badge}>
										{conversationCount > 9 ? (
											<span className={classes.badgeContent}>
												<span>9</span>
												<span className={classes.plus}>+</span>
											</span>
										) : (
											<span className={classes.number}>{conversationCount}</span>
										)}
									</span>
								</span>
							)}
						</CText>
					</div>
			);
	} else {
			return (
        <Accordion expanded={menuItem.open} square={true} onChange={() => handleChange(menuIndex)} style={{marginLeft: 10, marginRight: 10}}>
          <AccordionSummary expandIcon={<RightChevronIcon className={classes.rightIcon} />}>
            <div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
                {menuItem.icon}
            </div>
            <CText fontSize={15} fontWeight={500} color="light" label={menuItem.title} />
          </AccordionSummary>
          <AccordionDetails>
            {renderSubMenu(menuItem.submenu)}
          </AccordionDetails>
        </Accordion>
			);
	}
}

return (
	<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
					paper: classes.drawerZone
			}}
	>
			<Toolbar />
			{loading ? null : (
				<div className={classes.drawerContainer}>
					<List>
						{menu.map((menuItem, menuIndex) => (
							<React.Fragment key={menuItem.title} key={menuIndex}>
								{renderMenu(menuItem, menuIndex)}
								<Divider className={classes.divider} />
							</React.Fragment>
						))}
					</List>
				</div>
			)}
	</Drawer>
);
}

export const Menu = (props) => {
  const location = useLocation();
  const classes = useStylesSideMenu();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(state => state?.user);
  const userId = user?.info?.id;
  const teamId = user?.club?.team?.id;

  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const [conversationId, setConversationId] = useState("");

  const invitationCount = useSelector((state) => state?.invitations?.count);
  const conversation = useSelector((state) =>
    state?.conversations?.conversations.find(convo => convo.id === conversationId)
  );
  const conversationCount = conversation ? conversation.count : 0;

  const MessageSubscriptionRef = useRef(null);

  useEffect(() => {
    let subscription;
    let isMounted = true;

    const subscribeToNewConversations = async (season) => {
      try {
        const subscription = await API.graphql(
          graphqlOperation(subscriptions.onCreateConversation, { team_id: teamId, season: season })
        ).subscribe({
          next: async ({ value: { data } }) => {
            const newConversation = data.onCreateConversation;
            if (newConversation && isMounted) {
              setConversationId(newConversation.id);

              if (MessageSubscriptionRef.current) {
                MessageSubscriptionRef.current.unsubscribe();
                MessageSubscriptionRef.current = null;
              }

              MessageSubscriptionRef.current = await messagesCounter(
                userId,
                teamId,
                newConversation.id,
                isMounted,
                dispatch
              );
            }
          },
          error: error => console.error('Erreur de souscription à la création de conversation:', error),
        });

        return subscription;
      } catch (error) {
        console.error('Erreur lors de la souscription à onCreateConversation:', error);
      }
    };

    const fetchData = async () => {
      try {
        loadMenu();
        const session = await getSession();
        const groups = session.idToken.payload['cognito:groups'];

        if (groups && (groups.includes("Players") || groups.includes("Coaches"))) {
          const conversation = await fetchConversation(teamId);

          if (isMounted) {
            if (conversation && conversation.id) {
              setConversationId(conversation.id);

              if (MessageSubscriptionRef.current) {
                MessageSubscriptionRef.current.unsubscribe();
                MessageSubscriptionRef.current = null;
              }

              MessageSubscriptionRef.current = await messagesCounter(
                userId,
                teamId,
                conversation.id,
                isMounted,
                dispatch
              );
            } else {
              const currentSeason = await getCurrentSeason();
              await subscribeToNewConversations(currentSeason);
            }
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;

      if (MessageSubscriptionRef.current) {
        MessageSubscriptionRef.current.unsubscribe();
        MessageSubscriptionRef.current = null;
      }
    };
  }, [teamId, userId, dispatch]);

  const loadMenu = async () => {
    let fetchedMenu = await menuList(t, user);
    setMenu(fetchedMenu);
    setLoading(false);
  };

  const handleChange = (menuIndex) => {
    let newMenu = [...menu];
    newMenu[menuIndex].open = !menu[menuIndex].open;
    setMenu(newMenu);
  };

  const handleNavigate = (path, item) => {
    const teamName = user?.club?.team?.name;
    const currentPath = path.replace(':teamName', encodeURIComponent(teamName));

    const url = new URL(currentPath, window.location.origin);

    if (item.id === "calendar_and_results") {
      url.searchParams.set('team', teamName);
    }

    const finalPath = `${url.pathname}${url.search}`;
    navigate(finalPath);
    props.dropdownOpenCallback();
    window.scrollTo(0, 0);
  };

  const isPathActive = (currentPath, menuPath) => {
    const segmentsMenu = menuPath?.split('/');
    const segmentsCurrent = currentPath?.split('/');

    if (segmentsMenu?.length !== segmentsCurrent?.length) return false;

    for (let i = 0; i < segmentsMenu?.length; i++) {
      if (segmentsMenu[i] === ':teamName') continue;
      if (segmentsMenu[i] !== segmentsCurrent[i]) return false;
    }

    return true;
  };

  const renderSubMenu = (submenu) => {
    return submenu.map((subItem, submenuIndex) => {
      const isActiveSub = isPathActive(location.pathname, subItem.link_to);
      return (
        <div
          onClick={() => handleNavigate(subItem.link_to, subItem)}
          key={subItem.title}
          className={[
            classes.subTitleZone,
            isActiveSub ? classes.subTitleZoneFocus : ""
          ].join(" ")}
        >
          <div className={isActiveSub ? classes.menuIconFocus : classes.menuIcon}>
            {subItem.icon}
          </div>
          <CText fontSize={15} fontWeight={500} label={subItem.title} />
        </div>
      );
    });
  };

  const renderMenu = (menuItem, menuIndex) => {
    const isActiveMenu = isPathActive(location.pathname, menuItem.link_to);
    const isActiveSubMenu = menuItem.submenu.some(sub => isPathActive(location.pathname, sub.link_to));

    if (menuItem.submenu.length < 1) {
      return (
        <div
          onClick={() => handleNavigate(menuItem.link_to, menuItem)}
          className={[
            classes.titleZone,
            isActiveMenu ? classes.titleZoneFocus : ""
          ].join(" ")}
        >
          <div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
            {menuItem.icon}
          </div>
          <CText fontSize={15} fontWeight={500} style={{ position: 'relative' }}>
            {menuItem.title}
            {menuItem.id === "invitations" && invitationCount > 0 && (
              <span className={classes.badgeZone}>
                <span className={classes.badge}>
                  {invitationCount}
                </span>
              </span>
            )}
            {menuItem.id === "messages" && conversationCount > 0 && (
              <span className={classes.badgeZone}>
                <span className={classes.badge}>
                  {conversationCount > 9 ? (
                    <span className={classes.badgeContent}>
                      <span>9</span>
                      <span className={classes.plus}>+</span>
                    </span>
                  ) : (
                    <span className={classes.number}>{conversationCount}</span>
                  )}
                </span>
              </span>
            )}
          </CText>
        </div>
      );
    } else {
      return (
        <Accordion
          expanded={menuItem.open}
          square={true}
          onChange={() => handleChange(menuIndex)}
          style={{marginBottom: 1}} 
        >
          <AccordionSummary2 expandIcon={<RightChevronIcon className={classes.rightIcon} />}>
            <div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
              {menuItem.icon}
            </div>
            <CText fontSize={15} fontWeight={500} color="light" label={menuItem.title} />
          </AccordionSummary2>
          <AccordionDetails>
            {renderSubMenu(menuItem.submenu)}
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  if (loading) {
    return null;
  } else {
    return (
      <List>
        {menu.map((menuItem, menuIndex) => (
          <React.Fragment key={menuItem.title} key={menuIndex}>
            {renderMenu(menuItem, menuIndex)}
          </React.Fragment>
        ))}
      </List>
    );
  }
};
import { API } from 'aws-amplify';
import { setInvitations } from '../store/reducers/invitations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions'

const fetchInvitations = async (userId, dispatch) => {
  const listInvitationsFilter = {
    user_id: { eq: userId }
  };

  let listInvitations = await API.graphql({
    query: queries.listInvitations,
    variables: { filter: listInvitationsFilter },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })
  listInvitations = listInvitations.data?.listInvitations?.items
  listInvitations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const listUnreadedInvitationsFilter = {
    user_id: { eq: userId },
    invite_read: { eq: false },
    invite_status: { eq: 0 }
  };

  let listUnreadedInvitations = await API.graphql({
    query: queries.listInvitations,
    variables: { filter: listUnreadedInvitationsFilter },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })
  listUnreadedInvitations = listUnreadedInvitations.data?.listInvitations?.items

  const newInvitationsCount = listUnreadedInvitations.length;

  dispatch(setInvitations({
    count: newInvitationsCount,
    invitations: listInvitations
  }))
}

export const getRealTimeInvitations = async (userId, isMounted, dispatch) => {
  if(isMounted) {
    await fetchInvitations(userId, dispatch);
  }

  const subscription = API.graphql({
    query: subscriptions.onCreateTeamUser,
    variables: {
      user_id: userId
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  }).subscribe({
      next: async (eventData) => {
        if(isMounted) await fetchInvitations(userId, dispatch);
      },
      error: (error) => {
        console.error("Subscription error:", error);
      }
  });

  return subscription;
};
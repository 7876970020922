import React from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import { useStyles } from './TermsAndConditions.styles.js';

const TermsAndConditions = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Récupérer les sections depuis les traductions
  const sections = t('terms_and_conditions.sections', { returnObjects: true });

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t('terms_and_conditions.title')}</h1>
      <p className={classes.meta}>{t('terms_and_conditions.meta')}</p>
      
      {sections.map((section) => (
        <div key={section.id} className={classes.section}>
          <h2 className={classes.sectionTitle}>{section.title}</h2>
          {section.content && <p className={classes.sectionContent}>{section.content}</p>}
          {section.items && (
            <ul className={classes.itemsList}>
              {section.items.map((item, index) => (
                <li key={index} className={classes.item}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;
import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;
const COLOR_DARK = "#242834";

export const useStylesAuth = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: 0,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3%',
      paddingRight: '3%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  formBtn: {
    width: '100%',
    marginTop: 35
  },
  input: {
    marginBottom: 15
  },
  lastInput: {
    marginBottom: 0
  },
  inputInline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  inputRight: {
    marginLeft: 15
  },
  textZone: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20
  },
  
  form: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 1
    },
    [theme.breakpoints.up('lg')]: {
      flex: 1
    }
  },
  image: {
    height: '100%'
  },
  formZone: {
    backgroundColor: '#1A1919',
    borderRadius: 10,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 2*20px)',
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
      padding: 30
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
      padding: 30
    },
    [theme.breakpoints.up('md')]: {
      width: 400,
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      width: 450,
      padding: 30
    }
  },
  signFormZone: {
    backgroundColor: '#1A1919',
    borderRadius: 10,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 2*20px)',
      padding: 20
    },
    [theme.breakpoints.only('sm')]: {
      flex: 1,
      width: 450,
      padding: 30
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      padding: 30
    }
  },
  signFormZone2: {
    backgroundColor: '#1A1919',
    borderRadius: 10,
    height: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
		flexDirection: 'row',
    height: 0,
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 2*25px)',
      padding: 25
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% - 2*30px)',
      width: 450,
      padding: 30
    },
    [theme.breakpoints.only('md')]: {
      padding: 30
    }
  },
  formZone2Label: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '100%'
  },
  labelBtn: {
    all: 'unset',
    cursor: 'pointer',
    marginLeft: 8
  },
  labelBtnLabel: {
		"&:active, &:focus": {
      filter: 'brightness(90%)'
		}
  },
  labelBtnWrapper: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'right',
    width: '100%'
  },
  errorMandatoryFields: {
    marginLeft: 20
  }
}));
import { makeStyles } from "@material-ui/core/styles";

const TOP_BAR_HEIGHT = 56;
const LIGHT_COLOR = "#f4f4f4";
const PRIMARY_COLOR = "#45d69f";
const COLOR_PURPLE = "#9d44fd";
const COLOR_DARK = "#242834";

export const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#1f2126',
    padding: 50,
    marginTop: '30px'
  },
  footerTitle: {
    color: LIGHT_COLOR,
    marginBottom: 10
  },
  footerText: {
    color: LIGHT_COLOR,
    marginBottom: 10,
    cursor: 'pointer',
    "&:hover": {
      opacity: '0.8'
    }
  },
  languageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: LIGHT_COLOR,
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));
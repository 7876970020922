import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const COLOR_PRIMARY = '#45d69f';
const LABEL_COLOR = '#f2f2f2';
const ICON_COLOR = '#f2f2f2';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;

const useStyles = makeStyles((theme) => ({
  selectZone: {
    backgroundColor: TEXTFIELD_COLOR,
    borderRadius: 8,
    width: '100%',
    "& .MuiInputBase-root": {
      height: 48  
    },
    "& .MuiSelect-select": {
      paddingTop: 25,
      color: `${LABEL_COLOR} !important`,
      fill: `${LABEL_COLOR} !important`
    },
    "& .MuiFormControl-root": {
      backgroundColor: TEXTFIELD_COLOR
    }
  },
  select: {
    "& .MuiInput-underline:before": {
      display: 'none'
    },
    "&:hover:not(.Mui-disabled):before": {
      display: 'none'
    },
    "&&&:before": {
      borderBottom: "none"
    }
  },
  label: {
    color: `${LABEL_COLOR} !important`,
    marginTop: -4,
    "&.Mui-focused": {
      color: `${COLOR_PRIMARY} !important`,
      marginTop: -6
    },
    "&.MuiFormLabel-filled": {
      marginTop: -6
    },
    fontSize: "16px !important"
  },
  icon: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center'
  },
  filledInputRoot: {
    "&.MuiFilledInput-root": {
      backgroundColor: 'transparent',
    }
  },
  menuItemFocusVisible: {
    "&.Mui-focusVisible": {
      backgroundColor: "transparent"
    }
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      "&.Mui-focusVisible": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      }
    }
  },
  menuList: {
    transform: 'translateY(1px) !important'
  },
  menuItemSpan: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));

const CSelect = ({
  data,
  label,
  type,
  selectCallback,
  className,
  autoComplete = false,
  value
}) => {

  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState(value?.code ? value.code : "");

  const handleChange = (event) => {
    const selectedCode = event.target.value;
    const selectedItem = data.find(item => item.code === selectedCode);
  
    if (selectedItem) {
      setCurrentValue(selectedCode);  // Change to selectedCode
      selectCallback(selectedItem);
    }
  };

  return (
    <FormControl
      variant="filled"
      className={`${className} ${classes.selectZone}`}
    >
      <InputLabel
        className={classes.label}
      >
        {label}
      </InputLabel>
      <Select
        value={currentValue}
        onChange={handleChange}
        className={`${classes.select} ${classes.filledInputRoot}`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200
            }
          },
          className: classes.menuList
        }}
      >
        {data.map((item) => (
          <MenuItem
            key={item.code}
            value={item.code}
            className={classes.menuItem}
          >
            <span className={classes.menuItemSpan}>
              {item?.icon ? <span className={classes.icon}>{item.icon}</span> : null}
              <span>{item.label}</span>
            </span>
          </MenuItem>
        ))}
      </Select>

    </FormControl>
  );
}

export default CSelect;
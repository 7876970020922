import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import { ReactComponent as OpponentIcon } from '../../assets/icons/opponent.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots-vertical.svg';
import { getSession } from "../../utils/auth";
import { formatTime, getTimeFormat } from '../../utils/dates';
import MatchMenu from '../Menu/MatchMenu'; 

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const MatchCard = ({ data, className, index, withMenu, updateMatch, removeMatch }) => {
  const useStyles = makeStyles((theme) => ({
    dataZone: {
      display: 'block',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 15,
      listStyleType: 'none',
      width: '100%',
      boxSizing: 'border-box',
      [theme.breakpoints.up('xs')]: {
        padding: 20,
        maxWidth: 500
      },
      [theme.breakpoints.up('sm')]: {
        padding: 20,
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        padding: 20,
        maxWidth: 550,
      },
      [theme.breakpoints.up('lg')]: {
        padding: 20,
        maxWidth: 600
      },
      "&&:hover": {
        filter: 'brightness(95%)',
        boxShadow: 'none'
      }
    },
    matchInfo:{
      marginBottom: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    matchResult: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    teamLogoZone: {
      height: 45,
      width: 45,
      textAlign: 'center'
    },
    teamLogo: {
      height: '100%',
      width: 'auto'
    },
    opponentLogo: {
      height: '100%',
      width: 'auto',
      fill: COLOR_LIGHT
    },
    homeTeam: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    awayTeam: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'right'
    },
    scoreZoneWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    scoreZone: {
      backgroundColor: COLOR_LIGHT,
      width: 28,
      height: 35,
      marginLeft: 2,
      marginRight: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '10%'
    },
    score: {
      fontWeight: '700'
    },
    awayTeamName: {
      marginRight: 15
    },
    homeTeamName: {
      marginLeft: 15
    },
    moreBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 20,
      height: 20,
      fill: COLOR_LIGHT,
      cursor: 'pointer',
      borderRadius: 15,
      marginRight: 10,
      "&:hover": {
        backgroundColor: '#232e2f',
        filter: 'brightness(90%)',
        boxShadow: 'none'
      },
      marginTop: -10,
      marginRight: -10,
      marginLeft: 15,
      padding: 3
    }
  }))

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const timeFormat = getTimeFormat(i18n.language);

  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const session = await getSession();
        const groups = session.idToken.payload['cognito:groups'];
        setUserGroups(groups);
      }
    }

    fetchData();
  }, [data]);

  let dateFormat = i18n.language === "fr" ? "DD-MM-YYYY" : "MM-DD-YYYY";
  let formattedDate = moment(data.date).format(dateFormat);

  /******* Menu - Start *******/
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /******* Menu - End *******/

  const handleClickUpdateMatch = () => {
    updateMatch();
    setAnchorEl(null);
  }

  const handleRemoveMatch = async () => {
    try {
      handleClose();
      await removeMatch(data.id);
    } catch (error) {
      //console.error('Error removing match:', error);
    }
  };

  return (
    <div className={[classes.dataZone, className].join(" ")}>
      <Grid container className={classes.matchInfo}>
        <Grid container item xs={4}>
          <CText level="p1" color="light" label={`${t('common.week')} ${data.week}`} />
        </Grid>
        <Grid container item xs={8} justifyContent="flex-end">
          <CText level="p3" fontWeight={700} color="light" label={`${formattedDate} • ${formatTime(moment(data.hour, "HH:mm", true), timeFormat)}`} />
          {withMenu && (userGroups?.includes("Coaches") || userGroups?.includes("ClubAdmins")) ?
            <Tooltip title={t('match_card.contents.0.labels.0')}>
              <DotsIcon
                className={`${classes.moreBtn}`}
                onClick={handleClickMore}
              />
            </Tooltip>
            : null
          }

          <MatchMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleClickUpdateMatch={handleClickUpdateMatch}
            handleRemoveMatch={handleRemoveMatch}
            data={data}
            index={index}
          />
        </Grid>
      </Grid>
      <div className={classes.matchResult}>
        {data.home_team ?
          <Grid item xs={4} className={classes.homeTeam}>
            <div className={classes.teamLogoZone}>
              <ClubPhoto
                className={classes.teamLogo}
                src={data.home_team.club.logo}
              />
            </div>
            <CText className={classes.homeTeamName} level="p2" color="light" label={JSON.parse(data.home_team.club.names[0]).name} />
          </Grid>
          :
          <Grid item xs={4} className={classes.homeTeam}>
            <div className={classes.teamLogoZone}>
              <OpponentIcon className={classes.opponentLogo} />
            </div>
            <CText className={classes.homeTeamName} level="p2" color="light" label={data.home_team_name} />
          </Grid>
        }
        <Grid item xs={4}>
          { data?.home_team_goals && data?.home_team_goals !== null && data?.away_team_goals && data?.away_team_goals !== null ?
            <div className={classes.scoreZoneWrapper}>
              <div className={classes.scoreZone}>
                <CText className={classes.score} level="p1" fontWeight={700} color="dark">{data?.home_team_goals}</CText>
              </div>
              <div className={classes.scoreZone}>
                <CText className={classes.score} level="p1" fontWeight={700} color="dark">{data?.away_team_goals}</CText>
              </div>
            </div>
            : 
            <div className={classes.scoreZoneWrapper}>
              <CText level="p2" color="light" label={"-"} />
            </div>
          }
        </Grid>
        {data.away_team ?
          <Grid item xs={4} className={classes.awayTeam}>
            <CText className={classes.awayTeamName} level="p2" color="light" label={JSON.parse(data.away_team.club.names[0]).name} />
            <div className={classes.teamLogoZone}>
              <ClubPhoto
                className={classes.teamLogo}
                src={data.away_team.club.logo}
              /> 
            </div>
          </Grid>
          :
          <Grid item xs={4} className={classes.awayTeam}>
            <CText className={classes.awayTeamName} level="p2" color="light" label={data.away_team_name} />
            <div className={classes.teamLogoZone}>
              <OpponentIcon className={classes.opponentLogo} />
            </div>
          </Grid>
        }
      </div>
    </div>
  );
}

export default MatchCard;
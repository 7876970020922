import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CButton from "../../components/Button";
import { BadRequestSnackBar } from "../../components/SnackBar";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import CSelect from "../../components/Select";
import Scheduler from "./Scheduler";
import Dropdown from "../../components/DropdownSelector/Dropdown";
import TextInput from '../../components/TextInput';
import MatchCard from '../../components/Cards/MatchCard';
import { CDatePicker, CTimePicker } from '../../components/DatePicker';
import { CFormControlLabel, CFormLabel, CRadioGroup } from '../../components/Radio/Radio';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import NoTeamsCard from "../../components/Cards/NoTeamsCard";
import NoCompetitionsCard from "../../components/Cards/NoCompetitionsCard";
import { useStyles } from './calendar.styles';
import TeamsSelector from '../../components/DropdownSelector/TeamsSelector';
import CompetitionsSelector from '../../components/DropdownSelector/CompetitionsSelector';
import { getTeamIdFromUrlParams } from '../../utils/team';
import { getCurrentSeason } from "../../utils/season";
import { ReactComponent as CalendarFilterIcon } from '../../assets/icons/calendar-filter.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box'; 

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const WEEK_REF = [...new Array(50)].map((elmt, index) => ({ "label": (index+1).toString(), "code": index+1 }) );

const mapMatchesToSchedulerFormat = (matches) => {
  return matches.map((match) => {
    const homeTeamName = match?.home_team_name || 
      (match?.home_team?.club?.names?.length ? JSON.parse(match.home_team.club.names[0]).name : "Unknown Team");

    const awayTeamName = match?.away_team_name || 
      (match?.away_team?.club?.names?.length ? JSON.parse(match.away_team.club.names[0]).name : "Unknown Team");

    return {
      id: match.id,
      title: `${homeTeamName} - ${awayTeamName}`,
      start: new Date(`${match.date}T${match.hour || '00:00'}`),
      end: new Date(moment(`${match.date}T${match.hour || '00:00'}`).add(90, 'minutes').toDate())
    };
  });
};

const MatchFormModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [formType, setFormType] = useState('create');
  const [loaded, setLoaded] = useState(false);
  const [week, setWeek] = useState({ "label": "1", "code": 1 });
  const [opponentName, setOpponentName] = useState("");
  const [gamePitch, setGamePitch] = useState("home");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");

  useEffect(() => {
    if (props.data && props.index && props.index !== null) {
      setFormType('update');

      setWeek({ "label": `${props.data.week}`, "code": props.data.week });
      setDate(props.data.date);
      if (props.data.home_team_name) {
        setOpponentName(props.data.home_team_name);
        setGamePitch("away");
      } else if (props.data.away_team_name) {
        setOpponentName(props.data.away_team_name);
        setGamePitch("home");
      }
      setHour(`${props.data.date}T${props.data.hour}`);
    } else if(props.data) {
      if(props?.data?.date) { setDate(props?.data?.date); }
      if(props?.data?.hour) { setHour(props?.data?.hour); }
    }
    setLoaded(true);
  }, [props.data, props.index]);

  const selectWeek = (data) => setWeek(data);

  const opponentNameCB = (name) => setOpponentName(name);

  const handleChangeGamePitch = (data) => setGamePitch(data);

  const inputDate = (data) => setDate(data);

  const inputHour = (data) => setHour(data);

  const handleSubmit = async () => {
    try {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const formatedHour = moment(hour).format("HH:mm");

      if (week.code && opponentName && gamePitch && date && hour && formatedDate !== "Invalid date" && formatedHour !== "Invalid date") {
        const currentSeason = await getCurrentSeason();
        const team = props.team;
        const competition = props.competition;

        const matchInput = {
          week: week.code,
          season: currentSeason,
          date: formatedDate,
          hour: formatedHour,
          competition_id: competition.id,
          home_team_id: gamePitch === 'home' ? team.id : null,
          home_team_name: gamePitch === 'away' ? opponentName : null,
          away_team_id: gamePitch === 'away' ? team.id : null,
          away_team_name: gamePitch === 'home' ? opponentName : null
        };

        if (formType === 'create') {
          const createMatchResp = await API.graphql({ query: mutations.createMatch, variables: { input: matchInput }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
          const newMatch = createMatchResp.data.createMatch;
          props.addMatchCallback(newMatch);
        } else {
          matchInput.id = props.data.id;

          const updateMatchResp = await API.graphql({
            query: mutations.updateMatch,
            variables: { input: matchInput },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          const updatedMatch = updateMatchResp.data.updateMatch;
          props.updateMatchCallback(updatedMatch);
        }

        props.modalOpenCallback({ value: false });
      } else {
        let errorTitle, errorMessage;
        let errorElmts = [];

        if (!week.code || !opponentName || !gamePitch || !date || !hour) {
          if (!week.code) errorElmts.push(t('snack_bars.0.elements.5.label'));
          if (!opponentName) errorElmts.push(t('snack_bars.0.elements.6.label'));
          if (!gamePitch) errorElmts.push(t('snack_bars.0.elements.7.label'));
          if (!date) errorElmts.push(t('snack_bars.0.elements.8.label'));
          if (!hour) errorElmts.push(t('snack_bars.0.elements.9.label'));
          errorTitle = t('snack_bars.0.title');
          errorMessage = t('snack_bars.0.message');
        } else {
          if (formatedDate === "Invalid date") errorElmts.push(t('snack_bars.1.elements.0.label'));
          if (formatedHour === "Invalid date") errorElmts.push(t('snack_bars.1.elements.1.label'));
          errorTitle = t('snack_bars.1.title');
          errorMessage = t('snack_bars.1.message');
        }

        props.errorCallback({ errorTitle, errorMessage, errorElmts });
      }
    } catch (err) {
      //console.log('Error:', err);
    }
  };

  return (
    <div className={classes.modalContainer}>
      {loaded ? (
        <>
          <CSelect
            label={`${t('calendar.modals.0.inputs.0.label')} *`}
            data={WEEK_REF}
            selectCallback={selectWeek}
            className={classes.input}
            value={week}
          />
          <TextInput
            className={classes.input}
            label={`${t('calendar.modals.0.inputs.1.label')} *`}
            inputCallback={opponentNameCB}
            defaultValue={opponentName}
          />
          <FormControl className={`${classes.input}`}>
            <CFormLabel id="radio-buttons-group" label={`${t('calendar.modals.0.inputs.2.label')} *`} />
            <CRadioGroup
              defaultValue={gamePitch}
              onChange={handleChangeGamePitch}
            >
              <CFormControlLabel label={`${t('calendar.modals.0.labels.0.label')}`} value='home' />
              <CFormControlLabel label={`${t('calendar.modals.0.labels.1.label')}`} value='away' />
            </CRadioGroup>
          </FormControl>
          <div className={[classes.input, classes.inputInline].join(" ")}>
            <CDatePicker
              label={`${t('calendar.modals.0.inputs.3.label')} *`}
              inputCallback={inputDate}
              defaultValue={date}
            />
            <CTimePicker
              label={`${t('calendar.modals.0.inputs.4.label')} *`}
              className={classes.inputRight}
              inputCallback={inputHour}
              defaultValue={hour}
            />
          </div>
          <div className={classes.modalBtnZone}>
            <CButton
              className={classes.modalBtn}
              label={formType === 'create' ? `${t('calendar.modals.0.buttons.0.label')}` : `${t('calendar.modals.0.buttons.1.label')}`}
              type="contained"
              color="#45d69f"
              height={40}
              size={14}
              loader={true}
              onClick={handleSubmit}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

const Calendar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);
	const teamId = userStored?.club?.team?.id;
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState({value: false});
  const [season, setSeason] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamIndex, setTeamIndex] = useState(0);
  const [competitions, setCompetitions] = useState([]);
  const [competitionIndex, setCompetitionIndex] = useState(0);
  const [matches, setMatches] = useState([]);
  const [schedulerMatches, setSchedulerMatches] = useState([]);
  const [editable, setEditable] = useState(false);
  const [selectedMatchData, setSelectedMatchData] = useState(null);
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(null);
  const [dropdownCalendarTypeIndex, setDropdownCalendarTypeIndex] = useState(0);
  const [dropdownCalendarViewIndex, setDropdownCalendarViewIndex] = useState(0);

  const [currentView, setCurrentView] = useState('month');


  useEffect(() => {  
    const fetchData = async () => {
      await getUserType()
      const currentSeason = await getCurrentSeason();
      setSeason(currentSeason);
      let teams = await getTeams();

      if (teams.length > 0) {
        const teamId = await getTeamIdFromUrlParams(window.location.pathname, teams);
        const foundTeamIndex = teams.findIndex(team => team.id === teamId);
        if (foundTeamIndex !== -1) {
          setTeamIndex(foundTeamIndex);
        }

        let competitionsList = await getCompetitions(currentSeason, teams, foundTeamIndex)
        if(competitionsList?.length > 0) {
          await getTeamMatches(currentSeason, teams, foundTeamIndex, competitionsList, 0)
        }
      }
      setLoaded(true)
    }
  
    fetchData();
  }, [teamId]);

  const calendarTypes = [
    { "code": "list", "name": t('calendar.labels.1.label'), "icon": <ListIcon /> },
    { "code": "calendar", "name": t('calendar.labels.2.label'), "icon": <CalendarFilterIcon /> }
  ];

  const calendarViews = [
    { "code": 'month', "name": t('calendar.labels.3.label') },
    { "code": 'week', "name": t('calendar.labels.4.label') },
    { "code": 'day', "name": t('calendar.labels.5.label') }
  ];

  const getUserType = async() => {
    try {
      const groups = userStored?.groups
      if(groups.includes("Coaches") || groups.includes("ClubAdmins")) {
        setEditable(true)
      }
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id

        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        }
  
        let teamsList = await API.graphql({
          query: queries.listClubTeams,
          variables: { filter: teamsFilter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
        teamsList = teamsList.data.listTeams.items
        teams = teamsList

        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered
      } else {
        const team = club?.team
        teams.push(team)
      }

      setTeams(teams)

      return teams;
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getCompetitions = async(currentSeason, teams, teamIndex) => {
    try {
      const { groups } = userStored;
      let competitionArray = [];
      let teamCompetitions;

      if (groups.includes("ClubAdmins")) {
        teamCompetitions = teams[teamIndex]?.competitions?.items

        await teamCompetitions?.map(data => {
          competitionArray.push(data.competition);
        });
      } else if (groups.includes("Coaches") || groups.includes("Players")) {
        const competitionFilter = {
          season: { eq: currentSeason }
        }
  
        const getTeamResp = await API.graphql({
          query: queries.getTeamWithCompetitions,
          variables: { 
            id: teams[0].id,
            competitionFilter: competitionFilter
          }, authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
        teamCompetitions = getTeamResp.data.getTeam.competitions.items

        await teamCompetitions.map(data => {
          competitionArray.push(data.competition);
        });
      }

      const competitionsListOrdered = competitionArray.sort((a, b) => {
        const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
        const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
        return numberA - numberB;
      });

      const competitions = competitionsListOrdered

      setCompetitions(competitions)

      return competitions;
    }
    catch(err) { console.log(err) }
  }

  const getTeamMatches = async(season, teams, teamIndex, competitionsList, currentCompetitionIndex) => {
    try {
      const matchFilterSort = { field: "date", direction: "asc" };
      const matchFilter = {
        or: [
          { away_team_id: { eq: teams[teamIndex].id }},
          { home_team_id: { eq: teams[teamIndex].id }}
        ],
        competition_id: { eq: competitionsList[currentCompetitionIndex].id },
        season: { eq: season }
      };
  
      const searchMatchesResp = await API.graphql({
        query: queries.searchMatches,
        variables: { filter: matchFilter, sort: matchFilterSort },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      
      let searchMatches = searchMatchesResp?.data?.searchMatches?.items;
  
      if (searchMatches) {
        searchMatches = searchMatches.sort((a, b) => {
          const dateComparison = new Date(a.date) - new Date(b.date);
          if (dateComparison !== 0) {
            return dateComparison;
          }
  
          const timeA = a.hour ? a.hour.split(':').map(Number) : [0, 0];
          const timeB = b.hour ? b.hour.split(':').map(Number) : [0, 0];
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
      }
  
      setMatches(searchMatches);
      setSchedulerMatches(mapMatchesToSchedulerFormat(searchMatches));
    }
    catch(err) {
      //console.log(err);
    }
  };
  
  const onClickModalAdd = (data) => {
    if(data) {
      setSelectedMatchData(data);
    }

    setModalOpen({value: true});
  }

  // Add Match
  const addMatch = (newMatch) => {
    setMatches((prevMatches) => {
      const newMatches = [...prevMatches];
      for (let i = 0; i < newMatches.length; i++) {
        if (newMatch.date.localeCompare(newMatches[i].date) === -1) {
          newMatches.splice(i, 0, newMatch);
          setSchedulerMatches(mapMatchesToSchedulerFormat(newMatches));
          return newMatches;
        }
      }
      newMatches.push(newMatch);
      setSchedulerMatches(mapMatchesToSchedulerFormat(newMatches));
      return newMatches;
    });
  };
  
  // Update Match
  const matchUpdateCB = (updatedMatch) => {
    setMatches((prevMatches) => {
      const newMatches = prevMatches.map((match) =>
        match.id === updatedMatch.id ? updatedMatch : match
      );
      setSchedulerMatches(mapMatchesToSchedulerFormat(newMatches));
      return newMatches;
    });
  };

  //Delete Match
  const removeMatch = async (index) => {
    const matchToRemove = matches[index];
    try {
      await API.graphql({
        query: mutations.deleteMatch,
        variables: { input: { id: matchToRemove.id } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      
      setMatches((prevMatches) => {
        const newMatches = prevMatches.filter((_, i) => i !== index);
        setSchedulerMatches(mapMatchesToSchedulerFormat(newMatches));
        return newMatches;
      });
    } catch (err) {
      //console.error("Error deleting match:", err);
    }
  };

  const handleDropdownCalendarType = (index) => {
    setDropdownCalendarTypeIndex(index);
  };

  const handleDropdownCalendarView = (index) => {
    setDropdownCalendarViewIndex(index);
  };

  const handleViewChange = (view) => {
    const index = calendarViews.findIndex(calendarView => calendarView.code === view);
    
    if (index !== -1) {
      setDropdownCalendarViewIndex(index);
    }
  };

  /********* Handle Error *********/
  const [openSnack, setOpenSnack] = useState(false)
  const [snackBarErrorTitle, setSnackBarErrorTitle] = useState("")
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("")
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState("")

  const errorCB = (data) => {
    setOpenSnack(true)
    setSnackBarErrorTitle(data.errorTitle)
    setSnackBarErrorMessage(data.errorMessage)
    setSnackBarErrorElmts(data.errorElmts)
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setSnackBarErrorMessage("");
    setSnackBarErrorElmts("")
  }

  const onCloseModal = () => {
    setModalOpen({value: false});
    setSelectedMatchData(null);
    setSelectedMatchIndex(null);
  }

  // Content Modal
  const CONTENT_MODAL =
    <MatchFormModal
      team={teams[teamIndex]}
      competition={competitions[competitionIndex]}
      modalOpenCallback={(data) => setModalOpen(data)}
      data={selectedMatchData}
      index={selectedMatchIndex}
      addMatchCallback={addMatch}
      updateMatchCallback={matchUpdateCB}
      errorCallback={errorCB}
    />

  // Handle change team/competition
  const selectTeam = async(index) => {
    await setTeamIndex(index)
    let competitionsList = await getCompetitions(season, teams, index)

    await setCompetitionIndex(0)
    await setCompetitions(competitionsList)

    if(competitionsList.length > 0) {
      getTeamMatches(season, teams, index, competitionsList, competitionIndex)
    }

    const teamName = teams[index].name;
    const currentPath = window.location.pathname;
    const url = new URL(currentPath, window.location.origin);
    url.searchParams.set('team', teamName);
    const finalPath = `${url.pathname}${url.search}`;
    navigate(finalPath, { replace: true });
  }

  const selectCompetition = async(index) => {
    setCompetitionIndex(index)
    getTeamMatches(season, teams, teamIndex, competitions, index)
  }

  // Handle Match modification
  const handleClickUpdateMatch = async(index) => {
    setSelectedMatchData(matches[index]);
    setSelectedMatchIndex(index);
    setModalOpen({value: true});
  }

  //Matches Content
  let MATCHES_CONTENT = null;

  if (competitions.length > 0) {
    MATCHES_CONTENT = (
      <ul style={{ padding: 0 }}>
        <Grid container spacing={2}>
          {matches?.map((match, matchIndex) => (
            <Grid container item key={matchIndex} alignItems="center" justifyContent="center">
              <MatchCard
                data={match}
                updateMatch={() => handleClickUpdateMatch(matchIndex)}
                index={matchIndex}
                withMenu={true}
                removeMatch={() => removeMatch(matchIndex)} 
              />
            </Grid>
          ))}
        </Grid>
      </ul>
    );
  }

  // Content
  let CONTENT;

  if(loaded === true) {

    if(dropdownCalendarTypeIndex === 0){
      CONTENT = <div>        
        <div className={classes.topContainer}>
          {editable === true && teams.length > 0 && competitions.length?
            <div className={classes.topButtonsContainer}>
              <CButton
                className={classes.addButton}
                label={t('button.add')}
                type="contained"
                color={COLOR_PRIMARY}
                size={14}
                minWidth={BUTTON_WIDTH}
                maxWidth={BUTTON_WIDTH}
                onClick={() => onClickModalAdd()}
              />
            </div>
            : null
          }
        </div>

        {userStored?.groups?.includes("ClubAdmins") && teams.length < 1 ? (
            <div className={classes.noTeamsInfo}>
              <NoTeamsCard redirectBtn={true} />
            </div>
          ) : (userStored?.groups?.includes("ClubAdmins") || userStored?.groups?.includes("Coaches")) && competitions.length < 1 ? (
            <div className={classes.noTeamsInfo}>
              <NoCompetitionsCard redirectBtn={true} />
            </div>
          ) : MATCHES_CONTENT
        }
      </div>
    }
    else {
      CONTENT = <div style={{width: 1200}}>
        <Scheduler
          events={schedulerMatches}
          updateMatch={(index) => handleClickUpdateMatch(index)}
          removeMatch={(index) => removeMatch(index)}
          addMatch={(data) => onClickModalAdd(data)}
          currentView={calendarViews[dropdownCalendarViewIndex].code}
          onViewChange={handleViewChange}
        />
      </div>
    }
  } else {
    CONTENT = <div></div>
  }

  return(
    <div className={classes.container}>
      <CText
        className={classes.title}
        level="h1"
        color="light"
        loading={!loaded}
      >
        {t('calendar.title')} • {season}
      </CText>

      {loaded ?
        <div className={classes.filtersContainer}>
          <CText
            className={classes.title}
            level="p1"
            color="light"
          >
            <FilterIcon className={classes.filterIcon}/> Filtres
          </CText>
        
          <div className={classes.topContainerLeft}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              {loaded === true && userStored?.groups?.includes("ClubAdmins") && teams.length > 0 ?
                <TeamsSelector
                  teams={teams}
                  selectedTeamIndex={teamIndex}
                  onSelect={selectTeam}
                />
                : null
              }
              {loaded === true && competitions.length > 0 ?
                <CompetitionsSelector
                  competitions={competitions}
                  selectedCompetitionIndex={competitionIndex}
                  onCompetitionSelect={selectCompetition}
                />
                : null
              }

              <Dropdown
                options={calendarTypes}
                onSelect={handleDropdownCalendarType}
                selectedIndex={dropdownCalendarTypeIndex}
                type="icon"
                sx={{ marginRight: 2 }}
              />

              {calendarTypes[dropdownCalendarTypeIndex].code === "calendar" ?
                <Dropdown
                  options={calendarViews}
                  onSelect={handleDropdownCalendarView}
                  selectedIndex={dropdownCalendarViewIndex}
                  sx={{ marginRight: 0 }}
                /> : null
              }
            </Box>
          </div>
        </div>
        :
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={100}
          className={classes.filterContainer}
        />
      }
      {CONTENT}

      <BadRequestSnackBar
        open={openSnack}
        title={snackBarErrorTitle}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        errorElmts={snackBarErrorElmts}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />

      <CModal
        open={modalOpen.value}
        onClose={() => onCloseModal()}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}
  
export default Calendar;
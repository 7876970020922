export const listClubs = `query listClubs {
  listClubs {
    items {
      id
      logo
      names
      createdAt
      country_code
      cities
    }
  }
}`;

export const listUsers = `query listUsers($filter: ModelUserFilterInput) {
  listUsers(filter: $filter) {
    items {
      id
      email
      first_name
      last_name
      photo_path
    }
  }
}`;

export const getUser = `query getUser($id: ID!) {
  getUser(id: $id) {
    id
    first_name
    last_name
    email
    phone_country_code
    phone_dial_code
    phone_number
    nationality_code
    birthday
    height
    weight
    type
    gender
    photo_path
    createdAt
    updatedAt
  }
}`;

export const getUserTeamHistorical = `query getUser($id: ID!, $teamFilter: ModelTeamUserFilterInput) {
  getUser(id: $id) {
    teams(filter: $teamFilter) {
      items {
        position
        season
        team {
          id
          name
          club {
            id
            logo
            names
          }
        }
      }
    }
  }
}`;

export const getUserTeamsWithConversation = `query getUser($id: ID!, $teamFilter: ModelTeamUserFilterInput, $conversationFilter: ModelConversationFilterInput) {
  getUser(id: $id) {
    teams(filter: $teamFilter) {
      items {
        position
        season
        team {
          id
          name
          club {
            id
            logo
            names
          }
          conversations(filter: $conversationFilter) {
            items {
              id
            }
          }
        }
      }
    }
  }
}`;

export const getPlayerStats = `query getUser($id: ID!, $assistFilter: ModelAssistFilterInput,
  $cardFilter: ModelCardFilterInput, $goalFilter: ModelGoalFilterInput, $matchFilter: ModelMatchPlayerFilterInput) {
  getUser(id: $id) {
    assists(filter: $assistFilter)  {
      items {
        id
      }
    }
    cards(filter: $cardFilter)  {
      items {
        id
        type
      }
    }
    goals(filter: $goalFilter)  {
      items {
        id
      }
    }
    matches (filter: $matchFilter){
      items {
        id
      }
    }
  }
}`;

export const listMatchesResults = `query listMatches($filter: ModelMatchFilterInput) {
  listMatches(filter: $filter) {
    items {
      away_team_id
      away_team_goals
      home_team_id
      home_team_goals
      date
    }
  }
}`;

export const getClub = `query getClub($id: ID!) {
  getClub(id: $id) {
    id
    names
    logo
    country_code
    cities
    emails
    phones
    federation_id
    createdAt
    updatedAt
  }
}`;

export const getClubAdmin = `query getClubAdmin($id: ID!) {
  getClubAdmin(id: $id) {
    club_id
    createdAt
    email
    first_name
    id
    last_name
    updatedAt
  }
}`;

export const getCompetition = `query getCompetition($id: ID!) {
  getCompetition(id: $id) {
    id
    name
    logo
    country_code
    zone
    category
    gender
    federation_id
    createdAt
    updatedAt
  }
}`;

export const getTeam = `query getTeam($id: ID!) {
  getTeam(id: $id) {
    id
    name
    club {
      id
      names
      logo
    }
  }
}`;

export const getTeamWithCompetitions = `query getTeam($id: ID!, $competitionFilter: ModelCompetitionTeamFilterInput) {
  getTeam(id: $id) {
    id
    competitions(filter: $competitionFilter) {
      items {
        competition {
          id
          type
          name
        }
      }
    }
  }
}`;

export const getTeamPlayers = `query getTeam($id: ID!, $userFilter: ModelTeamUserFilterInput) {
  getTeam(id: $id) {
    users(filter: $userFilter, limit: 200) {
      items {
        user {
          first_name
          email
          id
          last_name
          nationality_code
          photo_path
        }
        position
      }
    }
  }
}`;

export const getTactic = `query getTactic($id: ID!) {
  getTactic(id: $id) {
    id
    title
    schema
    notes
    team_id
    createdAt
    updatedAt
  }
}`;

export const getTeamPlayersWithMatchesInfo = `query getTeam($id: ID!, $userFilter: ModelTeamUserFilterInput,
  $assistFilter: ModelAssistFilterInput, $cardFilter: ModelCardFilterInput, $goalFilter: ModelGoalFilterInput,
  $matchFilter: ModelMatchPlayerFilterInput) {
  getTeam(id: $id) {
    users(filter: $userFilter) {
      items {
        id
        user {
          first_name
          id
          last_name
          photo_path
          assists(filter: $assistFilter)  {
            items {
              id
            }
          }
          goals(filter: $goalFilter) {
            items {
              id
            }
          }
          cards(filter: $cardFilter)  {
            items {
              id
              type
            }
          }
          matches (filter: $matchFilter){
            items {
              id
            }
          }
        }
        position
      }
    }
  }
}`;

export const getTeamPlayersMembership = `query getTeam(
  $id: ID!,
  $userFilter: ModelTeamUserFilterInput,
  $userClubDocumentFilter: ModelUserClubDocumentFilterInput
  $userClubContributionFilter: ModelUserClubContributionFilterInput) {
  getTeam(id: $id) {
    users(filter: $userFilter) {
      items {
        user {
          first_name
          id
          last_name
          photo_path
          documents(filter: $userClubDocumentFilter)  {
            items {
              id
              id_card_path
              license_path
              medical_certificate_path
              health_questionnaire_path
              club_rules_path
              image_rights_form_path
            }
          }
          contributions(filter: $userClubContributionFilter)  {
            items {
              id
              season
              paid
            }
          }
        }
        position
      }
    }
  }
}`;

export const listAppSubscriptions = `query listAppSubscriptions($filter: ModelAppSubscriptionFilterInput) {
  listAppSubscriptions(filter: $filter){
    items {
      id
      club_id
      start_date
      end_date
      session_id
      subscription_id
      plan {
        id
        name
        max_teams
      }
    }
  }
}`;

export const listBodyFats = `query listBodyFats($filter: ModelBodyFatFilterInput) {
  listBodyFats(filter: $filter){
    items {
      id
      date
      body_fat_percentage
      createdAt
    }
  }
}`;

export const listMatchPlayers = `query listMatchPlayers($filter: ModelMatchPlayerFilterInput, $userTeamFilter: ModelTeamUserFilterInput) {
  listMatchPlayers(filter: $filter){
    items {
      user {
        first_name
        id
        last_name
        photo_path
        teams(filter: $userTeamFilter) {
          items {
            position
          }
        }
      }
      id
      captain
      position
      start_at
    }
  }
}`;

export const listMatchPlayersScore = `query listMatchPlayers($filter: ModelMatchPlayerFilterInput) {
  listMatchPlayers(filter: $filter){
    items {
      match {
        home_team_id
        home_team_goals
        away_team_id
        away_team_goals
        date
      }
    }
  }
}`;

export const listPlans = `query listPlans {
  listPlans{
    items {
      id
      is_limited
      max_teams
      name
      price_euro
      first_month_free
      extra_team_price_euro
    }
  }
}`;

export const listPlayerMatchInfo = `query listMatchPlayers($filter: ModelMatchPlayerFilterInput) {
  listMatchPlayers(filter: $filter){
    items {
      defensive_rating
      collective_rating
      offensive_rating
      rating
    }
  }
}`;

export const listClubAdmins = `query listClubAdmins {
  listClubAdmins{
    items {
      id
      first_name
      last_name
      email
      club {
        id
        names
      }
    }
  }
}`;

export const listTeamsWithClub = `query listTeams(
  $filter: ModelTeamFilterInput,
  $userFilter: ModelTeamUserFilterInput
) {
listTeams(filter: $filter) {
  items {
    id
    name
    category
    gender
    club {
      id
      names
      logo
    }
    users(filter: $userFilter) {
      items {
        user {
          first_name
          email
          id
          last_name
          photo_path
          type
        }
        invite_status
      }
    }
  }
}
}`;

export const listClubTeams = `query listTeams(
  $filter: ModelTeamFilterInput,
  $competitionFilter: ModelCompetitionTeamFilterInput
) {
listTeams(filter: $filter) {
  items {
    id
    name
    category
    gender
    club_id
    competitions(filter: $competitionFilter) {
      items {
        id
        competition {
          id
          name
          type
        }
      }
    }
  }
}
}`;

export const listTactics = `query listTactics ($filter: ModelTacticFilterInput) {
  listTactics(filter: $filter) {
    items {
      id
      title
    }
  }
}`;

export const listTeams = `query listTeams(
    $filter: ModelTeamFilterInput,
    $competitionFilter: ModelCompetitionTeamFilterInput,
    $userFilter: ModelTeamUserFilterInput
  ) {
  listTeams(filter: $filter) {
    items {
      id
      name
      category
      gender
      club_id
      competitions(filter: $competitionFilter) {
        items {
          id
          competition {
            id
            name
            type
          }
        }
      }
      users(filter: $userFilter) {
        items {
          user {
            first_name
            email
            id
            last_name
            photo_path
            type
          }
          invite_status
        }
      }
    }
  }
}`;

export const listInjuries = `query listInjuries {
  listInjuries {
    items {
      id
      label_en
      label_fr
    }
  }
}`;

export const listInvitations = `query listInvitations($filter: ModelInvitationFilterInput) {
  listInvitations(filter: $filter) {
    items {
      id
      invite_status
      invite_read
      user_type
      team_id
      team {
        id
        name
        club {
          id
          names
          logo
        }
      }
      createdAt
    }
  }
}`;

export const listChampionships = `query listChampionships {
  listChampionships{
    items {
      id
      logo
      name
      country_code
      zone
      category
      gender
      federation_id  
    }
  }
}`;

export const listChampionshipGroups = `query listChampionshipGroups {
  listChampionshipGroups{
    items {
      id
      name
      federation_number
    }
  }
}`;

export const listPlayerInjuries = `query listPlayerInjuries($filter: ModelPlayerInjuryFilterInput) {
  listPlayerInjuries(filter: $filter) {
    items {
      id
      duration
      start_date
      injury {
        id
        label_en
        label_fr
      }
    }
  }
}`;

export const listWeights = `query listWeights($filter: ModelWeightFilterInput) {
  listWeights(filter: $filter) {
    items {
      id
      date
      weight
      createdAt
    }
  }
}`;

export const listSeasons = `query listSeasons {
  listSeasons(limit: 1000) {
    items {
      id
      name
    }
  }
}`;

export const searchSeasons = `query searchSeasons {
  searchSeasons(
    sort: { direction: desc, field: name }
  ){
    items {
      id
      name
    }
  }
}`;

export const searchChampionshipClubs = `query searchChampionshipClubs {
  searchChampionshipClubs {
    items {
      id
      club {
        id
        logo
        names
      }
      championship_group {
        id
        name
      }
    }
  }
}`;

export const searchMatches = `query searchMatches($filter: SearchableMatchFilterInput, $sort: [SearchableMatchSortInput], $limit: Int) {
  searchMatches(filter: $filter, sort: $sort, limit: $limit) {
    items {
      away_team_goals
      away_team_name
      away_team_formation
      competition_group_id
      competition_id
      createdAt
      date
      home_team_goals
      home_team_name
      home_team_formation
      hour
      id
      stadium_address
      stadium_name
      week
      home_team {
        id
        club {
          id
          logo
          names
          official
        }
      }
      away_team {
        id
        club {
          id
          logo
          names
          official
        }
      }
    }
  }
}`;

export const searchUsers = `query searchUsers($filter: SearchableUserFilterInput) {
  searchUsers(filter: $filter) {
    items {
      id
      email
      first_name
      last_name
      photo_path
    }
  }
}`;

export const searchUsersByTeam = `query searchUsers($filter: SearchableUserFilterInput) {
  searchUsers(filter: $filter) {
    items {
      id
      email
      first_name
      last_name
      photo_path
    }
  }
}`;
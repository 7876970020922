import React, { useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import {makeStyles} from "@material-ui/core/styles"

const LABEL_COLOR = '#f4f4f4'
const COLOR_PRIMARY = '#45d69f'
const TEXTFIELD_COLOR = '#242834'
const iconSize = 28

const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontSize: "16px !important",
    "&.MuiFormLabel-root": {
      color: LABEL_COLOR,
      "&.Mui-focused": {
        color: COLOR_PRIMARY
      }
    },
  },
  radioGroup: {
    color: LABEL_COLOR
  }
}));

export const CRadioGroup = ({children, onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "");

  const onChangeValue = (event) =>{
    setValue(event.target.value);
    onChange(event.target.value);
  }

  const classes = useStyles();
  return (
    <RadioGroup
      value={value}
      onChange={onChangeValue}
      className={`${classes.radioGroup}`}
    >
      {children}
    </RadioGroup>
  );
}

export const CFormLabel = ({id, label}) => {
  const classes = useStyles();
  return (
    <FormLabel
      id={id}
      className={`${classes.formLabel}`}
    >
      {label}
    </FormLabel>
  );
}

export const CFormControlLabel = ({value, label, onChange}) => {
  return (
    <FormControlLabel
      value={value}
      onChange={onChange}
      control={<Radio
        sx={{
          color: LABEL_COLOR,
          marginLeft: "6px",
          marginRight: "2px",
          width: 35,
          height: 35,
          '&.Mui-checked': {
            color: COLOR_PRIMARY,
          },
          '&&.hover': {
            color: "red",
          }
        }}
      />}
      label={label}
    />
  );
}

export const CRadio = ({value, checked, onChange}) => {
  return (
    <Radio
      value={value}
      checked={checked}
      onChange={onChange}
      sx={{
        color: LABEL_COLOR,
        marginLeft: "6px",
        marginRight: "2px",
        width: 35,
        height: 35,
        '&.Mui-checked': {
          color: COLOR_PRIMARY,
        },
        '&&.hover': {
          color: "red",
        }
      }}
    />
  )
}
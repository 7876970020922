import React, { useState, useEffect } from 'react';
import TextInput from '../../components/TextInput.js';
import CButton from "../../components/Button.js";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { sessionHandler } from '../../utils/auth';
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import { CText } from "../../components/Text";
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import CSelect from "../../components/Select";
import { useDispatch } from 'react-redux';
import { useStylesAuth } from './auth.styles.js';
import { BadRequestSnackBar } from "../../components/SnackBar";
import authentImg from '../../assets/images/authent.svg';

const COLOR_PRIMARY = '#45d69f';

const SignUp = (props) => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const classes = useStylesAuth();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("");
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState([]);
  
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [type, setType] = useState(null);
  const [searchParams] = useSearchParams();

  const invitationId = searchParams.get("invitation");
  const invitationType = searchParams.get("type");

  // Click Buttons
  const signUpClick = async() => {
    let errorMessage;

    if(firstName && lastName && email && password && type?.code) {
      // Call backend top pass data form
      try {
        const user = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email,
            'custom:first_name': firstName,
            'custom:last_name': lastName,
            'custom:user_type': type?.code,
            'custom:locale': i18n.language
          }
        });
        if(user.userConfirmed === false) {
          console.log("Verification Code.");
          navigate({
            pathname: `/auth/verification_code`,
            search: createSearchParams({
              email: email,
              password: password,
              invitation: invitationId
            }).toString()
          });
        } else {
          if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log("Change password.");
            props.formTypeCallback("new_pwd");
            props.userAuthentCallback(user);
          } else {
            console.log("User authenticated.")
            await dispatch(signIn())
            sessionHandler()
          }
        }
      } catch (error) {
        console.log('error signing in', error)
        setSnackBarErrorMessage("")
        setSnackBarErrorElmts([])
        setOpenSnack(true)
        setSnackBarErrorMessage(t('sign_up.errors.1.message'))
      }
    } else {
      let errorElmts = []
      setSnackBarErrorMessage("")
      setSnackBarErrorElmts([])
      setSnackBarErrorMessage(t('sign_up.errors.0.message'))
      if(!firstName) {
        errorElmts.push(t('sign_up.inputs.0.label'))
      }
      if(!lastName) {
        errorElmts.push(t('sign_up.inputs.1.label'))
      }
      if(!email) {
        errorElmts.push(t('sign_up.inputs.2.label'))
      }
      if(!password) {
        errorElmts.push(t('sign_up.inputs.3.label'))
      }
      if(!type) {
        errorElmts.push(t('sign_up.inputs.4.label'))
      }

      setOpenSnack(true)
      setSnackBarErrorMessage(t('sign_up.errors.0.message'))
      setSnackBarErrorElmts(errorElmts)
    }
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setSnackBarErrorMessage("");
  }

  const signIn = () => {
    navigate(`/auth/sign_in`);
    window.scrollTo(0, 0);
  }

  // Infos Callbacks
  const firstNameCb = (data) =>{ setFirstName(data) }
  const lastNameCb = (data) =>{ setLastName(data) }
  const emailCb = (data) =>{ setEmail(data) }
  const passwordCb = (data) =>{ setPassword(data) }
  const typeCB = (data) => {
    setType(data)
  }

  const userType = [
    { "label": t('user_types.0.label'), "code": "C" },
    { "label": t('user_types.1.label'), "code": "P" },
    { "label": t('user_types.2.label'), "code": "CA" }
  ]

  useEffect(() => {
    const fetchData = async () => {
      //Get the invitation and set type
      if(invitationType) {
        setType({ code: invitationType });
      }
    }
  
    fetchData()
  }, [])

  return(
    <div className={classes.container}>
      <Helmet>
        <title>{t('sign_up.head.title')}</title>
        <meta name="description" content={t('sign_up.head.meta')} />
      </Helmet>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={false} md={6} className={classes.form}>
          <div className={classes.signFormZone}>
            <div className={classes.inputInline}>
              <TextInput className={classes.input} label={`${t('sign_up.inputs.0.label')} *`} inputCallback={firstNameCb} />
              <TextInput className={[classes.input, classes.inputRight].join(" ")} label={`${t('sign_up.inputs.1.label')} *`} inputCallback={lastNameCb} />
            </div>
            <TextInput className={classes.input} label={`${t('sign_up.inputs.2.label')} *`} inputCallback={emailCb} />
            <TextInput className={classes.input} label={`${t('sign_up.inputs.3.label')} *`} type="password" inputCallback={passwordCb} />
            {!invitationId ?
              <CSelect
                label={`${t('sign_up.inputs.4.label')} *`}
                data={userType}
                selectCallback={typeCB}
                className={classes.lastInput}
              />
              : null
            }
            <CButton
              className={classes.formBtn}
              label={t('sign_up.buttons.0.label')}
              height={38}
              type="contained"
              loader={true}
              onClick={async () => await signUpClick()}
            />
          </div>
          <div className={classes.signFormZone2}>
            <CText level="p2" color="light" className={classes.formZone2Label}>
              {t('sign_up.messages.0.label')}
              <button className={classes.labelBtn} onClick={() => signIn()}>
                <CText
                  level="p2"
                  fontWeight="700"
                  color="primary"
                  className={classes.labelBtnLabel}
                  label={t('sign_up.buttons.1.label')}
                />
              </button>
            </CText>
          </div>
        </Grid>
        <Grid item xs={false} sm={false} md={6}>
          <img src={authentImg} className={classes.image} />
        </Grid>
      </Grid>
      <BadRequestSnackBar
        open={openSnack}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        errorElmts={snackBarErrorElmts}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
}

export default SignUp;
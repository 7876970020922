import React, { useState, useEffect, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import * as queries from '../../graphql/queries.js'
import * as mutations from '../../graphql/mutations.js'

import CButton from "../../components/Button.js"
import TextInput from '../../components/TextInput.js'
import SwipeableViews from 'react-swipeable-views';
import {CTab, TabPanel } from '../../components/Menu/index';

import TextEditor from "../../components/TextEditor/index"
import Canvas2 from "../../components/tacticalDrawer/Canvas2.js"

import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import { useStylesTactic } from './tactics.styles.js';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const Tactic = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStylesTactic();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tacticId = searchParams.get('id');
  const userStored = useSelector((state) => state?.user)

  const [title, setTitle] = useState(null);
  const [newTitle, setNewTitle] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [notes, setNotes] = useState("");
  const [newNotes, setNewNotes] = useState("");
  const [schema, setSchema] = useState([]);
  const [newSchema, setNewSchema] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); 

  useEffect(() => {
    const fetchTactic = async () => {
      try {
        const { data } = await API.graphql({
          query: queries.getTactic,
          variables: { id: tacticId },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        if (data && data.getTactic) {
          const { title, schema, notes } = data.getTactic;
          setTitle(title);
          setSchema(schema);
          setNotes(notes);

          setIsLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching tactic:', error);
      }
    };

    if (tacticId) {
      fetchTactic();
    }
  }, [tacticId]);

  const handleChangeTab = (index) => {
    setTabIndex(index);
  };

  const handleTitleChange = (data) => {
    setNewTitle(data);
  };

  const canvasOnchange = (data) => {
    setNewSchema(data);
  };

  const textEditorOnchange = (data) => {
    console.log(data)
    setNewNotes(data);
  };

  const TAB = [
    {
      label: t('tactic.tabs.0.labels.0')
    },
    {
      label: t('tactic.tabs.0.labels.1')
    }
  ];

  const handlePrevious = () => {
    searchParams.delete('id');
    navigate({ search: searchParams.toString() });
  };

  const onClickSave = async() => {
    let tactic = {
      id: tacticId
    };

    if(newTitle && newTitle !== title) {
      tactic.title = newTitle;
    }

    if(newSchema && newSchema !== schema) {
      tactic.schema = JSON.stringify(newSchema);
    }

    if(newNotes && newNotes !== notes) {
      tactic.notes = newNotes;
    }

    await API.graphql({
      query: mutations.updateTactic,
      variables: { input: tactic },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  }

  return(
    <div className={classes.container}>
      {isLoaded ?
      <>
        <div className={classes.topContainerWrapper}>
          <LeftArrowIcon
            className={classes.previousIcon}
            onClick={() => handlePrevious()}
          />
          <div className={classes.topContainer}>
            <TextInput
              className={classes.tacticalSchemaTitle}
              label={t('tactic.inputs.0.label')}
              defaultValue={title}
              inputCallback={(data) => handleTitleChange(data)}
            />

            <div className={classes.topContainerBtns}>
              <CButton
                className={classes.addButton}
                label={t('tactic.buttons.0.label')}
                type="contained"
                color={COLOR_PRIMARY}
                size={14}
                loader={true}
                onClick={() => onClickSave()}
              />
            </div>
          </div>
        </div>

        <CTab
          items={TAB}
          onClickCB={handleChangeTab}
        />
        <SwipeableViews
          slideStyle={{ overflow: 'visible'}}
          containerStyle={{
            transition: 'transform 0.4s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={handleChangeTab}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            <Canvas2
              defaultValue={JSON.parse(schema)}
              onChange={canvasOnchange}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            <TextEditor
              defaultValue={notes}
              onChange={textEditorOnchange}
            />
          </TabPanel>
        </SwipeableViews>
      </>
      :
      <div></div>
      }
    </div>
  )
}
  
export default Tactic;
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom"
import { CText } from "../../components/Text";
import teamImg from '../../assets/images/team.svg';
import dataImg from '../../assets/images/data.svg';
import chatImg from '../../assets/images/chat.svg';
import { CTab } from '../../components/Menu/index';
import ModalApp from '../../components/Modal/ModalApp.js';
import { ReactComponent as TeamFlagIcon } from '../../assets/icons/team-flag.svg';
import { ReactComponent as PlayerIcon } from '../../assets/icons/player.svg';
import { ReactComponent as CoachIcon } from '../../assets/icons/coach.svg';
import { useStyles } from './Home.styles.js';
import Footer from './Footer.js';
import CButton from "../../components/Button";
import './animation.css';
import appLogo from "../../assets/images/app_logo.png"

const COLOR_PRIMARY = '#45d69f';

const Timeline = ({ steps }) => {
  const classes = useStyles();

  return (
    <div className={classes.timeline}>
      {steps.map((step, index) => (
        <div className={classes.stepContainer} key={index}>
          <div className={classes.step} key={index}>
            <div className={classes.stepCircle}></div>
            {index < steps.length - 1 && <div className={classes.stepLine}></div>}
          </div>
          <div className={classes.stepText}>{step}</div>
        </div>
      ))}
    </div>
  );
};

const Home = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [menuIndex, setMenuIndex] = useState(0);
  const [isIos, setIsIos] = useState(false);
  const [showIosPrompt, setShowIosPrompt] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    
    if(isStandalone) {
      navigate(`/auth/sign_in`);
    } else {
      const savedPrompt = window.sessionStorage.getItem('deferredPrompt');
      const savedIsIos = window.sessionStorage.getItem('isIos');
    
      if (savedPrompt) setDeferredPrompt(savedPrompt);
      if (savedIsIos) setIsIos(savedIsIos === 'true');
    
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        window.sessionStorage.setItem('deferredPrompt', e);
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      setLoading(false);
      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }
  }, []);

  // Start - To remove
  const [showCounterLabel, setShowCounterLabel] = useState(true);
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade-out');
      setTimeout(() => {
        setShowCounterLabel(prevState => !prevState);
        setFadeClass('fade-in');
      }, 1000);
    }, 15000);

    return () => clearInterval(interval);
  }, []);
  // End - To remove

  const handleInstallClick = () => {
    if (isIos) {
      setShowIosPrompt(true);
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      console.log('Install prompt not available');
    }
  };

  const renderIosInstructions = () => (
    <div className={classes.iosPrompt}>
      <p>{t('ios_instructions.0.label')}</p>
      <ol>
        <li>{t('ios_instructions.1.label')}<strong>{t('ios_instructions.2.label')}</strong>{t('ios_instructions.3.label')}</li>
        <li>{t('ios_instructions.4.label')}<strong>{t('ios_instructions.5.label')}</strong>.</li>
        <li>{t('ios_instructions.6.label')}<strong>{t('ios_instructions.7.label')}</strong>.</li>
      </ol>
      <button onClick={() => setShowIosPrompt(false)}>{t('ios_instructions.8.label')}</button>
    </div>
  );

  const howItWorkSteps = [
    {
      icon: <TeamFlagIcon className={classes.stepMenuIcon} />,
      label: `${t('home.user_menus.0.label')}`,
      steps: [
        t('home.contents.5.label'),
        t('home.contents.6.label'),
        t('home.contents.7.label'),
        t('home.contents.8.label')
      ]
    },
    {
      icon: <CoachIcon className={classes.stepMenuIcon} />,
      label: `${t('home.user_menus.1.label')}`,
      steps: [
        t('home.contents.9.label'),
        t('home.contents.10.label'),
        t('home.contents.11.label'),
        t('home.contents.12.label')
      ]
    },
    {
      icon: <PlayerIcon className={classes.stepMenuIcon} />,
      label: `${t('home.user_menus.2.label')}`,
      steps: [
        t('home.contents.13.label'),
        t('home.contents.14.label'),
        t('home.contents.15.label'),
        t('home.contents.16.label')
      ]
    }
  ];

  const content = [
    {
      "class": classes.content1,
      "title": `${t('home.contents.0.title')}`,
      "titleColor": classes.title1,
      "text": `${t('home.contents.0.label')}`,
      "textColor": classes.text1,
      "image": teamImg
    },
    {
      "class": classes.content2,
      "title": `${t('home.contents.1.title')}`,
      "titleColor": classes.title2,
      "text": `${t('home.contents.1.label')}`,
      "textColor": classes.text2,
      "image": dataImg
    },
    {
      "class": classes.content1,
      "title": `${t('home.contents.2.title')}`,
      "titleColor": classes.title1,
      "text": `${t('home.contents.2.label')}`,
      "textColor": classes.text1,
      "image": chatImg
    }
  ];

  const handleMenuIndex = (index) => {
    setMenuIndex(index);
  };

  return (
    !loading && (
      <div className={classes.container}>
        <Helmet>
          <title>{t('home.head.title')}</title>
          <meta name="description" content={t('home.head.meta')} />
        </Helmet>

        <div className={classes.backgroundImage}>


          {showCounterLabel ? (
            <div
              className={`counter-content ${fadeClass} ${fadeClass === 'fade-out' ? 'hidden' : ''}`}
              style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}
            >
              <CText
                className={[classes.counterLabel].join(" ")}
                color="light">
                  {t('counter.0.label')}
              </CText>

              <CText
                className={classes.counterLabel}
                color="light"
              >
                {t('counter.1.label')}{' '}
                <span className={classes.counterLabelSpan}>
                  {t('counter.2.label')}
                </span>
              </CText>

              <span style={{display: 'flex', flexDirection: 'row'}}>
                <img src={appLogo} className={classes.counterAppLogo} />
                <CText
                  color="light"
                  className={classes.counterAppName}
                >
                  Football Connexion
                </CText>
              </span>
            </div>
          ) : (
            <div
              className={`home-content ${fadeClass} ${fadeClass === 'fade-out' ? 'hidden' : ''}`}
            >
              <div className={[classes.homeContent].join(" ")}>
                <CText
                  className={[classes.homeTitle].join(" ")}
                  color="light"
                >
                  {t('home.contents.3.title')}
                </CText>
                <CText
                  className={[classes.text].join(" ")}
                  color="light">
                    {t('home.contents.3.label')}
                </CText>
              </div>
            </div>
          )}

          { 1 === 0 ? <div className={classes.installBtnWrapper}>
            <CButton
              className={classes.installBtn}
              label={t('home.buttons.0')}
              type="text"
              color={COLOR_PRIMARY}
              size={16}
              height={38}
              onClick={() => handleInstallClick()}
            />
          </div> :  null}
        </div>

        <div className={classes.timelineSection}>
          <CText className={classes.homeTitle} color="dark">
            {t('home.contents.4.title')}
          </CText>

          <CTab items={howItWorkSteps} onClickCB={handleMenuIndex} />
          <Timeline steps={howItWorkSteps[menuIndex].steps} />
        </div>

        {content.map((item, index) => (
          <Grid container key={`home_content_${index}`} spacing={2} className={[classes.content, item.class].join(" ")}>
            {index % 2 === 0 ? (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <CText className={[classes.title, item.titleColor].join(" ")} label={item.title} />
                  <CText className={[classes.text, item.textColor].join(" ")} label={item.text} />
                </Grid>
                <Grid container item xs={12} sm={12} md={6} justifyContent="center">
                  <img src={item.image} className={classes.image} alt={item.title} />
                </Grid>
              </>
            ) : (
              <>
                <Grid container item xs={12} sm={12} md={6} justifyContent="center">
                  <img src={item.image} className={classes.image} alt={item.title} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CText className={[classes.title, item.titleColor].join(" ")} label={item.title} />
                  <CText className={[classes.text, item.textColor].join(" ")} label={item.text} />
                </Grid>
              </>
            )}
          </Grid>
        ))}

        <Footer />

        <ModalApp
          open={showIosPrompt}
          onClose={() => setShowIosPrompt(false)}
        >
          {renderIosInstructions()}
        </ModalApp>
      </div>
    )
  );
};

export default Home;
// MatchMenu.jsx
import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as ChangesIcon } from '../../assets/icons/changes.svg';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const COLOR_DARK = '#242834';

const useStyles = makeStyles(() => ({
  listIcon: {
    width: 17,
    height: 17,
    fill: COLOR_DARK,
  },
  listIconZone: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const MatchMenu = ({ anchorEl, handleClose, handleClickUpdateMatch, handleRemoveMatch, data, index }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          minWidth: '10ch',
        },
      }}
      autoFocus={false}
    >
      <MenuItem onClick={(e) => handleClickUpdateMatch(e, data, index)}>
        <span className={classes.listIconZone}>
          <ChangesIcon className={classes.listIcon} />
        </span>
        {t('match_card.contents.0.labels.1')}
      </MenuItem>
      <MenuItem onClick={handleRemoveMatch}>
        <span className={classes.listIconZone}>
          <TrashIcon className={classes.listIcon} />
        </span>
        {t('match_card.contents.0.labels.2')}
      </MenuItem>
    </Menu>
  );
};

export default MatchMenu;